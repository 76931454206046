import {useRef, useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import {IoMdClose} from "react-icons/io"
import {IoArrowBackOutline} from "react-icons/io5"

import {
  useCheckUserMutation,
  useImageUploadMutation,
  useSignUpWithImageMutation,
} from "../../../../Store/api/ApiSlice"

import PhoneInputBox from "../../../atoms/PhoneInputBox"
import EditProfile from "../../../../assets/images/profileEdit.png"
import {toast} from "react-toastify"
import "./style.css"

const UserDetails = ({
  setView,
  setImageUrl,
  imageUrl,
  usrname,
  email,
  setEmail,
  setPhoneNumber,
  phoneNumber,
  setReferralCode,
  referrelCode,
  fullName,
  setFullName,
  setUniqueCode,
}) => {
  const [SignUpWithImage, {isSuccess}] = useSignUpWithImageMutation()
  const fileInputRef = useRef(null)
  const [uploadFile, {isSuccess: uploadSuccess}] = useImageUploadMutation()
  const [CheckUserExist, {isSuccess: checkUserSuccess}] = useCheckUserMutation()
  const handleFullNameeChange = (e) => setFullName(e.target.value)
  const handleReferralChange = (e) => setReferralCode(e.target.value)
  // const handleEmailChange = (e) => setEmail(e.target.value)
  const handleDivClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    let formData = new FormData()
    formData.append("image", file)
    if (file) {
      uploadFile(formData)
        .then((res) => {
          // console.log("🚀 ~ uploadFile ~ res:", res)
          setImageUrl(res?.data?.url)
        })
        .catch((error) => {
          console.log("🚀 ~ uploadFile ~ error:", error)
        })
      // Handle the file upload
      // onUpload(file);
    }
  }
  const userDetailsFunc = (e) => {
    e.preventDefault()
    const data = {
      user_name: usrname,
      email: email,
      phone: phoneNumber,
      referralCode: referrelCode,
    }
    // console.log("🚀 ~ userDetailsFunc ~ data:", data, fullName)
    CheckUserExist({phone: phoneNumber})
      .then((responce) => {
        console.log("🚀 ~ .then ~ res:", responce)
        SignUpWithImage(data)
          .then((res) => {
            if (responce?.data?.data?.length == 0) {
              if (res?.data) {
                if (res?.data?.data?.uniqueCode) {
                  localStorage.setItem(
                    "uniqueCode",
                    JSON.stringify({uniqueCode: res?.data?.data?.uniqueCode})
                  )
                  setUniqueCode(res?.data?.data?.uniqueCode)
                } else {
                  let data = localStorage.getItem("uniqueCode")
                  data = JSON.parse(data)
                  setUniqueCode(data?.uniqueCode)
                }
                setView(4)
              }
            }
          })
          .catch((error) => {
            console.log("🚀 ~ userDetailsFunc ~ error:", error)
          })
      })
      .catch((error) => {
        console.log("🚀 ~ userDetailsFunc ~ error:", error)
      })
  }
  return (
    <>
      <Modal.Header>
        <div className="flex gap-2 items-center">
          <IoArrowBackOutline
            color="#ffffff"
            fontSize={30}
            onClick={() => setView(2)}
            className="cursor-pointer"
          />
          <h3 className="fs-4 text-white fw-600">Personal Information</h3>
        </div>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setView(0)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="text-white mb-2 d-flex flex-col">
          <h5 className="text-white fw-600">Enter Personal Information</h5>
          <p className="fs-6">
            Information Needed for when it's time to send you payments.
          </p>
          <>
            <div className="text-center py-3 d-flex justify-center">
              <img
                src={imageUrl !== null ? imageUrl : EditProfile}
                alt="profile icon"
                width={80}
                height={80}
                onClick={handleDivClick}
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50px",
                }}
              />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{display: "none"}}
              onChange={handleFileChange}
              accept="image/*"
            />
          </>
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="formFullname">
            <Form.Control
              type="text"
              placeholder="Full Name"
              onChange={handleFullNameeChange}
            />
          </Form.Group>
          <div className="mb-3" controlId="formUsername">
            <PhoneInputBox
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
            />
          </div>
          <Form.Group className="mb-3" controlId="formReferralCode">
            <Form.Control
              type="text"
              placeholder="Refferal Code(optional)"
              onChange={handleReferralChange}
            />
          </Form.Group>
          <div className=" w-100 pt-3 d-flex flex-col items-center">
            <Button
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
              onClick={userDetailsFunc}
            >
              Next
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  )
}

export default UserDetails
