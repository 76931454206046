import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AOS from "aos";

import bg from "../../assets/641db10559166e4faea5e642_Gradient.webp";
import bg2 from "../../assets/backgrounds/Rectangle1.png";
import people from "../../assets/images/peoples 1.png";
import competemobile from "../../assets/images/iPhone 12 Pro Mockup Front View.png";
import competemobile2 from "../../assets/images/Group 57.png";
import glows from "../../assets/backgrounds/Rectangle1.png";
// import competemobile1 from "../../assets/competemobile1.png";
import "./Work.css";

const Work = () => {
  // Function to handle the button click and redirect to the App Store URL
  const redirectToAppStore = () => {
    window.location.href =
      "https://apps.apple.com/in/app/competeae/id6444800738";
  };

  return (
    <div className="pos">
      <img className="mobile-background" src={glows} alt="glows" />
      <Container className="work-container">
        <Row>
          <Col lg={5} md={12} sm={12} className="mt-lg-5 mt-0 center">
            <h2 className="gl-h2 container-8-2 ">Bet On What You Control</h2>
            <p className="gl-p">
              No Longer do you have to depend on someone on the TV to win your
              Bets, Create a goal or Challenge Friends and Bet on yourself.
            </p>
            {/* <button className="cta-btnh ms-0" onClick={redirectToAppStore}>
            Download App Now
          </button> */}
          </Col>
          <Col lg={4} md={12} sm={12}>
            <div className="work-bg">
              <img
                data-aos="fade-up"
                data-aos-duration="3000"
                className="left"
                src={people}
                alt=""
              />
            </div>
          </Col>
          <Col lg={3} md={12} sm={12}>
            <div className="work-bg">
              <img className="gl-s2-gradient mobile-background d-lg-block d-none" src={bg} alt="" />
              <img className="mobile-background-check d-lg-none d-block" src={bg2} alt="" />


              <div className="d-flex justify-content-center">
                <img
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  className="size_mob d-lg-none d-block"
                  src={competemobile}
                  alt=""
                />
                <img
                  className="gl-s2-profile rounded "
                  src={competemobile2}
                  alt=""
                />
              </div>

              <img
                data-aos="fade-up"
                data-aos-duration="3000"
                className="gl-s2-portfolio rounded d-lg-block d-none"
                src={competemobile}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Work;
