import React from "react";

const Overlay = ({ zIndex }) => {
  return (
    <div
      className="overlay"
      style={{
        zIndex: zIndex,
      }}
    />
  );
};

export default Overlay;
