import React from "react";
import { Container } from "react-bootstrap";

import PersonIcon from "../../../assets/icons/personIcon.svg";

const ThingsToknow = ({ data }) => {
  return (
    <div className="d-flex pb-5 thinksToTopSec">
      <Container className="container-9 d-flex flex-col gap-4">
        <h1 className="fs-33 fw-700 text-center mobile_size textSecondary">
          Things to know
        </h1>
        <div className="d-flex justify-center flex-wrap gap-4 guestReqSec">
          {data?.informationNeeded?.map((infoNeeded, index) => (
            <div
              key={index}
              className="col-md-3 col-12 d-flex gap-3 items-center"
            >
              <img
                src={infoNeeded?.icon}
                width={50}
                height={50}
                alt="personIcon"
              />
              <div className="d-flex flex-col">
                <h4 className="fw-500 textSecondary mb-0">
                  {infoNeeded?.title}
                </h4>
                <p className="fw-500">{infoNeeded?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ThingsToknow;
