import FooterSection from "../components/footerSection";
import HeaderNav from "./../components/headerNav";

function TC() {
  return (
    <div className="w-full h-auto bg-[#080402] text-white">
      {/* //Notes: Header */}
      <HeaderNav />

      <div className=" px-8 md:px-24">
        <div className="leading-8">
          <h1 className="text-white text-4xl md:text-7xl bold ">
            Terms & Conditions
          </h1>
          <p className="text-[#EDCB76] mt-4">
            Last updated on : September 21st , 2023
          </p>

          {/* Table of content */}

          <p className="mt-4 text-[#EDCB76] font-bold mb-4">
            {" "}
            TABLE OF CONTENTS
          </p>
          <ol className="list-decimal ml-8">
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#agreements">AGREEMENT TO TERMS</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#ip">INTELLECTUAL PROPERTY RIGHTS</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a data-custom-class="link" href="#userreps">
                    USER REPRESENTATIONS
                  </a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#userreg">USER REGISTRATION</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#prohibited">PROHIBITED ACTIVITIES</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#ugc">USER GENERATED CONTRIBUTIONS</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#license">CONTRIBUTION LICENSE</a>{" "}
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#mobile">MOBILE APPLICATION LICENSE</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#submissions">SUBMISSIONS</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#thirdparty">THIRD-PARTY WEBSITE AND CONTENT</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#advertiser">ADVERTISERS</a>
                </span>
              </p>
            </li>

            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#sitemanage">SITE MANAGEMENT</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#privacypolicy1">PRIVACY POLICY</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#copyright2">COPYRIGHT INFRINGEMENTS</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#terms">TERM AND TERMINATION</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#modifications">MODIFICATIONS AND INTERRUPTIONS</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#guarantee">GOVERNING LAW</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#disputes">DISPUTE RESOLUTION</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#corrections">CORRECTIONS</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#disclaimer">DISCLAIMER</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#liability">LIMITATIONS OF LIABILITY</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#indemnification">INDEMNIFICATION</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#userdata">USER DATA</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#electronic">
                    ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#california">CALIFORNIA USERS AND RESIDENTS</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#misc">MISCELLANEOUS</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#profit">PROFIT SHARING</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#cop">CONDITIONS OF PARTICIPATION</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#game">GAME ENTRY </a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#pwp">PAYMENT AND WITHDRAWAL OF PRIZES</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#price">PRIZES</a>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="text-[#EDCB76]">
                  <a href="#contact">CONTACT US</a>
                </span>
              </p>
            </li>
          </ol>
          <hr className="border-gray-900 mt-10" />
        </div>
        <div className="mt-4">
          <p className="text-[#EDCB76] font-bold mb-4" id="agreements">
            1. AGREEMENT TO TERMS
          </p>
          <p className="mb-4">
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”)
            and COMPETE AE INC. ("Company," “we," “us," or “our”), concerning
            your access to and use of the competeae.com website as well as any
            other media form, media channel, mobile website or mobile
            application related, linked, or otherwise connected thereto
            (collectively, the “Site”). We are registered in New York, United
            States and have our registered office at 293 N BROADWAY, YONKERS, NY
            10701. You agree that by accessing the Site, you have read,
            understood, and agreed to be bound by all of these Terms of Use. IF
            YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
            EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE
            USE IMMEDIATELY.
          </p>
          <p className="mb-4">
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use at any time and for
            any reason. We will alert you about any changes by updating the
            “Last updated” date of these Terms of Use, and you waive any right
            to receive specific notice of each such change. Please ensure that
            you check the applicable Terms every time you use our Site so that
            you understand which Terms apply. You will be subject to, and will
            be deemed to have been made aware of and to have accepted, the
            changes in any revised Terms of Use by your continued use of the
            Site after the date such revised Terms of Use are posted.
          </p>
          <p className="mb-4">
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </p>
          <p className="mb-4">
            The Site is not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use this Site. You may not use the Site in a way that would violate
            the Gramm-Leach-Bliley Act (GLBA).
          </p>
          <p>
            The Site is intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register for
            the Site.
          </p>
          {/* point 2 of T&C */}
          <p className="text-[#EDCB76] font-bold mb-4 mt-10" id="ip">
            2. INTELLECTUAL PROPERTY RIGHTS
          </p>
          <p className="mb-4">
            Unless otherwise indicated, the Site is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the “Content”) and the trademarks, service marks, and
            logos contained therein (the “Marks”) are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the United States, international copyright laws,
            and international conventions. The Content and the Marks are
            provided on the Site “AS IS” for your information and personal use
            only. Except as expressly provided in these Terms of Use, no part of
            the Site and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </p>
          <p>
            Provided that you are eligible to use the Site, you are granted a
            limited license to access and use the Site and to download or print
            a copy of any portion of the Content to which you have properly
            gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to the Site,
            the Content and the Marks.
          </p>
          {/* point 3 of T&C */}
          <p className="text-[#EDCB76] font-bold mb-4 mt-10" id="userreps">
            3. USER REPRESENTATIONS
          </p>
          <p className="mb-4">
            By using the Site, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Terms
            of Use; (4) you are not a minor in the jurisdiction in which you
            reside; (5) you will not access the Site through automated or
            non-human means, whether through a bot, script, or otherwise; (6)
            you will not use the Site for any illegal or unauthorized purpose;
            and (7) your use of the Site will not violate any applicable law or
            regulation.
          </p>
          <p>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Site (or any portion thereof).
          </p>
          {/* point 4 of T&C */}
          <p className="text-[#EDCB76] font-bold mb-4 mt-10" id="userreg">
            4. USER REGISTRATION
          </p>
          <p>
            You may be required to register with the Site. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </p>
          {/* point 5 of T&C */}
          <p className="text-[#EDCB76] font-bold mb-4 mt-10" id="prohibited">
            5. PROHIBITED ACTIVITIES
          </p>
          <p className="mb-4">
            You may not access or use the Site for any purpose other than that
            for which we make the Site available. The Site may not be used in
            connection with any commercial endeavors except those that are
            specifically endorsed or approved by us.
          </p>
          <p className="mb-4">As a user of the Site, you agree not to:</p>

          <ul className="list-disc ml-8 md:ml-14">
            <li>
              <p className="my-4">
                Systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or enforce
                limitations on the use of the Site and/or the Content contained
                therein.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Use any information obtained from the Site in order to harass,
                abuse, or harm another person.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Make improper use of our support services or submit false
                reports of abuse or misconduct.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Use the Site in a manner inconsistent with any applicable laws
                or regulations.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Engage in unauthorized framing of or linking to the Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Site or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, or maintenance of the Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Delete the copyright or other proprietary rights notice from any
                Content.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Attempt to impersonate another user or person or use the
                username of another user.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                “spyware” or “passive collection mechanisms” or “pcms”).
              </p>
            </li>
            <li>
              <p className="mb-4">
                Interfere with, disrupt, or create an undue burden on the Site
                or the networks or services connected to the Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Site to you.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Attempt to bypass any measures of the Site designed to prevent
                or restrict access to the Site, or any portion of the Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Except as permitted by applicable law, decipher, decompile,
                disassemble, or reverse engineer any of the software comprising
                or in any way making up a part of the Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Site, or using or launching any unauthorized script or other
                software.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Use a buying agent or purchasing agent to make purchases on the
                Site.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Use the Site as part of any effort to compete with us or
                otherwise use the Site and/or the Content for any
                revenue-generating endeavor or commercial enterprise.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Use the Site to advertise or offer to sell goods and services.
              </p>
            </li>
            <li>
              <p className="mb-4">Sell or otherwise transfer your profile.</p>
            </li>
          </ul>

          {/* point 6 of T&C*/}

          <p className="mb-8 text-[#EDCB76] font-bold" id="ugc">
            6. USER GENERATED CONTRIBUTIONS
          </p>
          <p className="mb-4">
            The Site may invite you to chat, contribute to, or participate in
            blogs, message boards, online forums, and other functionality, and
            may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Site, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions, or personal information or other material
            (collectively, "Contributions"). Contributions may be viewable by
            other users of the Site and through third-party websites. As such,
            any Contributions you transmit may be treated as non-confidential
            and non-proprietary. When you create or make available any
            Contributions, you thereby represent and warrant that:
          </p>
          <ul className="list-disc ml-8 md:ml-14">
            <li>
              <p mb className="mb-4">
                The creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.
              </p>
            </li>
            <li>
              <p className="mb-4">
                You are the creator and owner of or have the necessary licenses,
                rights, consents, releases, and permissions to use and to
                authorize us, the Site, and other users of the Site to use your
                Contributions in any manner contemplated by the Site and these
                Terms of Use.
              </p>
            </li>
            <li>
              <p className="mb-4">
                You have the written consent, release, and/or permission of each
                and every identifiable individual person in your Contributions
                to use the name or likeness of each and every such identifiable
                individual person to enable inclusion and use of your
                Contributions in any manner contemplated by the Site and these
                Terms of Use.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions are not false, inaccurate, or misleading.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us).
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions do not ridicule, mock, disparage, intimidate,
                or abuse anyone.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions do not violate any applicable law,
                regulation, or rule.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions do not violate the privacy or publicity
                rights of any third party.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions do not violate any applicable law concerning
                child pornography, or otherwise intended to protect the health
                or well-being of minors.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions do not include any offensive comments that
                are connected to race, national origin, gender, sexual
                preference, or physical handicap.
              </p>
            </li>
            <li>
              <p className="mb-4">
                Your Contributions do not otherwise violate, or link to material
                that violates, any provision of these Terms of Use, or any
                applicable law or regulation.
              </p>
            </li>
          </ul>
          <p className="mt-8">
            Any use of the Site in violation of the foregoing violates these
            Terms of Use and may result in, among other things, termination or
            suspension of your rights to use the Site.{" "}
          </p>

          {/* point 7 of T&C*/}
          <p className="my-8 text-[#EDCB76] font-bold" id="license">
            7. CONTRIBUTION LICENSE
          </p>
          <p className="mb-4">
            By posting your Contributions to any part of the Site, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid,
            worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels.
          </p>
          <p className="mb-4">
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions.
          </p>
          <p className="mb-4">
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on
            the Site. You are solely responsible for your Contributions to the
            Site and you expressly agree to exonerate us from any and all
            responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </p>
          <p>
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2) to re-categorize
            any Contributions to place them in more appropriate locations on the
            Site; and (3) to pre-screen or delete any Contributions at any time
            and for any reason, without notice. We have no obligation to monitor
            your Contributions.
          </p>

          {/* point 8 of T&C*/}
          <p className="my-8 text-[#EDCB76] font-bold" id="mobile">
            8. MOBILE APPLICATION LICENSE
          </p>
          <p className="mb-4 font-bold">Use License</p>
          <p className="mb-4">
            {" "}
            If you access the Site via a mobile application, then we grant you a
            revocable, non-exclusive, non-transferable, limited right to install
            and use the mobile application on wireless electronic devices owned
            or controlled by you, and to access and use the mobile application
            on such devices strictly in accordance with the terms and conditions
            of this mobile application license contained in these Terms of Use.
            You shall not: (1) except as permitted by applicable law, decompile,
            reverse engineer, disassemble, attempt to derive the source code of,
            or decrypt the application; (2) make any modification, adaptation,
            improvement, enhancement, translation, or derivative work from the
            application; (3) violate any applicable laws, rules, or regulations
            in connection with your access or use of the application; (4)
            remove, alter, or obscure any proprietary notice (including any
            notice of copyright or trademark) posted by us or the licensors of
            the application; (5) use the application for any revenue generating
            endeavor, commercial enterprise, or other purpose for which it is
            not designed or intended; (6) make the application available over a
            network or other environment permitting access or use by multiple
            devices or users at the same time; (7) use the application for
            creating a product, service, or software that is, directly or
            indirectly, competitive with or in any way a substitute for the
            application; (8) use the application to send automated queries to
            any website or to send any unsolicited commercial e-mail; or (9) use
            any proprietary information or any of our interfaces or our other
            intellectual property in the design, development, manufacture,
            licensing, or distribution of any applications, accessories, or
            devices for use with the application.{" "}
          </p>

          <p className="mb-4 font-bold">Apple and Android Devices</p>
          <p className="mb-4">
            The following terms apply when you use a mobile application obtained
            from either the Apple Store or Google Play (each an “App
            Distributor”) to access the Site: (1) the license granted to you for
            our mobile application is limited to a non-transferable license to
            use the application on a device that utilizes the Apple iOS or
            Android operating systems, as applicable, and in accordance with the
            usage rules set forth in the applicable App Distributor’s terms of
            service; (2) we are responsible for providing any maintenance and
            support services with respect to the mobile application as specified
            in the terms and conditions of this mobile application license
            contained in these Terms of Use or as otherwise required under
            applicable law, and you acknowledge that each App Distributor has no
            obligation whatsoever to furnish any maintenance and support
            services with respect to the mobile application; (3) in the event of
            any failure of the mobile application to conform to any applicable
            warranty, you may notify the applicable App Distributor, and the App
            Distributor, in accordance with its terms and policies, may refund
            the purchase price, if any, paid for the mobile application, and to
            the maximum extent permitted by applicable law, the App Distributor
            will have no other warranty obligation whatsoever with respect to
            the mobile application; (4) you represent and warrant that (i) you
            are not located in a country that is subject to a U.S. government
            embargo, or that has been designated by the U.S. government as a
            “terrorist supporting” country and (ii) you are not listed on any
            U.S. government list of prohibited or restricted parties; (5) you
            must comply with applicable third-party terms of agreement when
            using the mobile application, e.g., if you have a VoIP application,
            then you must not be in violation of their wireless data service
            agreement when using the mobile application; and (6) you acknowledge
            and agree that the App Distributors are third-party beneficiaries of
            the terms and conditions in this mobile application license
            contained in these Terms of Use, and that each App Distributor will
            have the right (and will be deemed to have accepted the right) to
            enforce the terms and conditions in this mobile application license
            contained in these Terms of Use against you as a third-party
            beneficiary thereof.
          </p>

          {/* point 9 of T&C*/}
          <p className="mt-8 text-[#EDCB76] font-bold" id="submissions">
            9. SUBMISSIONS
          </p>
          <p className="my-4">
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </p>

          {/* point 10 of T&C*/}
          <p className="mt-8 text-[#EDCB76] font-bold" id="thirdparty">
            10. THIRD-PARTY WEBSITE AND CONTENT
          </p>
          <p className="my-4">
            The Site may contain (or you may be sent via the Site) links to
            other websites ("Third-Party Websites") as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties ("Third-Party
            Content"). Such Third-Party Websites and Third-Party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-Party Websites accessed through the Site or any Third-Party
            Content posted on, available through, or installed from the Site,
            including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access the Third-Party Websites or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices,
            of any website to which you navigate from the Site or relating to
            any applications you use or install from the Site. Any purchases you
            make through Third-Party Websites will be through other websites and
            from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not
            endorse the products or services offered on Third-Party Websites and
            you shall hold us harmless from any harm caused by your purchase of
            such products or services. Additionally, you shall hold us harmless
            from any losses sustained by you or harm caused to you relating to
            or resulting in any way from any Third-Party Content or any contact
            with Third-Party Websites.
          </p>

          {/* point 11 of T&C*/}
          <p className="mt-8 text-[#EDCB76] font-bold" id="advertiser">
            11. ADVERTISERS
          </p>
          <p className="my-4">
            We allow advertisers to display their advertisements and other
            information in certain areas of the Site, such as sidebar
            advertisements or banner advertisements. If you are an advertiser,
            you shall take full responsibility for any advertisements you place
            on the Site and any services provided on the Site or products sold
            through those advertisements. Further, as an advertiser, you warrant
            and represent that you possess all rights and authority to place
            advertisements on the Site, including, but not limited to,
            intellectual property rights, publicity rights, and contractual
            rights. We simply provide the space to place such advertisements,
            and we have no other relationship with advertisers.
          </p>

          {/* point 12 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="sitemanage">
            12. SITE MANAGEMENT
          </p>
          <p className="my-4">
            We reserve the right, but not the obligation, to: (1) monitor the
            Site for violations of these Terms of Use; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Site or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems;
            and (5) otherwise manage the Site in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Site.
          </p>

          {/* point 13 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="privacypolicy1">
            13. PRIVACY POLICY
          </p>
          <p className="my-4">
            We care about data privacy and security. Please review our Privacy
            Policy:{" "}
            <span className="text-[#EDCB76]">
              https://app.competeae.com/privacy.{" "}
            </span>{" "}
            By using the Site, you agree to be bound by our Privacy Policy,
            which is incorporated into these Terms of Use. Please be advised the
            Site is hosted in the United States. If you access the Site from any
            other region of the world with laws or other requirements governing
            personal data collection, use, or disclosure that differ from
            applicable laws in the United States, then through your continued
            use of the Site, you are transferring your data to the United
            States, and you agree to have your data transferred to and processed
            in the United States.
          </p>

          {/* point 14 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="copyright2">
            14. COPYRIGHT INFRINGEMENTS
          </p>
          <p className="my-4">
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Site infringes
            upon any copyright you own or control, please immediately notify us
            using the contact information provided below (a “Notification”). A
            copy of your Notification will be sent to the person who posted or
            stored the material addressed in the Notification. Please be advised
            that pursuant to applicable law you may be held liable for damages
            if you make material misrepresentations in a Notification. Thus, if
            you are not sure that material located on or linked to by the Site
            infringes your copyright, you should consider first contacting an
            attorney.
          </p>

          {/* point 15 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="terms">
            15. TERM AND TERMINATION
          </p>
          <p className="my-4">
            These Terms of Use shall remain in full force and effect while you
            use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
            USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
            BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
            NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
            USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </p>
          <p className="my-4">
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>

          {/* point 16 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="modifications">
            16. MODIFICATIONS AND INTERRUPTIONS
          </p>
          <p className="my-4">
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site.
          </p>
          <p className="my-4">
            We cannot guarantee the Site will be available at all times. We may
            experience hardware, software, or other problems or need to perform
            maintenance related to the Site, resulting in interruptions, delays,
            or errors. We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be
            construed to obligate us to maintain and support the Site or to
            supply any corrections, updates, or releases in connection
            therewith.{" "}
          </p>

          {/* point 17 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="guarantee">
            17. GOVERNING LAW
          </p>
          <p className="my-4">
            These Terms of Use and your use of the Site are governed by and
            construed in accordance with the laws of the State of New York
            applicable to agreements made and to be entirely performed within
            the State of New York, without regard to its conflict of law
            principles.
          </p>

          {/* point 18 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="disputes">
            18. DISPUTE RESOLUTION
          </p>
          <p className="my-4 font-bold">Binding Arbitration</p>
          <p className="my-4">
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association ("AAA")
            and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related Disputes ("AAA Consumer Rules"), both of which are
            available at the AAA website:{" "}
            <span className="text-[#EDCB76]"> www.adr.org. </span> Your
            arbitration fees and your share of arbitrator compensation shall be
            governed by the AAA Consumer Rules and, where appropriate, limited
            by the AAA Consumer Rules. If such costs are determined by the
            arbitrator to be excessive, we will pay all arbitration fees and
            expenses. The arbitration may be conducted in person, through the
            submission of documents, by phone, or online. The arbitrator will
            make a decision in writing, but need not provide a statement of
            reasons unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in
            Westchester County, New York. Except as otherwise provided herein,
            the Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.
          </p>

          <p className="my-4 ">
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts located in Westchester County, New York,
            and the Parties hereby consent to, and waive all defenses of lack of
            personal jurisdiction, and forum non conveniens with respect to
            venue and jurisdiction in such state and federal courts. Application
            of the United Nations Convention on Contracts for the International
            Sale of Goods and the Uniform Computer Information Transaction Act
            (UCITA) are excluded from these Terms of Use.
          </p>
          <p className="mb-4 ">
            In no event shall any Dispute brought by either Party related in any
            way to the Site be commenced more than one (1) years after the cause
            of action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>

          <p className="my-4 font-bold">Restrictions</p>
          <p className="my-4">
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </p>

          <p className="my-4 font-bold">Exceptions to Arbitration</p>
          <p className="my-4">
            {" "}
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning binding arbitration: (a) any Disputes
            seeking to enforce or protect, or concerning the validity of, any of
            the intellectual property rights of a Party; (b) any Dispute related
            to, or arising from, allegations of theft, piracy, invasion of
            privacy, or unauthorized use; and (c) any claim for injunctive
            relief. If this provision is found to be illegal or unenforceable,
            then neither Party will elect to arbitrate any Dispute falling
            within that portion of this provision found to be illegal or
            unenforceable and such Dispute shall be decided by a court of
            competent jurisdiction within the courts listed for jurisdiction
            above, and the Parties agree to submit to the personal jurisdiction
            of that court.{" "}
          </p>

          {/* point 19 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="corrections">
            19. CORRECTIONS
          </p>
          <p className="my-4">
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </p>

          {/* point 20 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="disclaimer">
            20. DISCLAIMERS
          </p>
          <p className="my-4">
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
            WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </p>

          {/* point 21 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="liability">
            21. LIMITATION OF LIABILITY
          </p>
          <p className="my-4">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
            US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
            ARISING OR $1,000.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL
            LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
            OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME
            OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU,
            AND YOU MAY HAVE ADDITIONAL RIGHTS.
          </p>

          {/* point 22 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="indemnification">
            22. INDEMNIFICATION
          </p>
          <p className="my-4">
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            your Contributions; (2) use of the Site; (3) breach of these Terms
            of Use; (4) any breach of your representations and warranties set
            forth in these Terms of Use; (5) your violation of the rights of a
            third party, including but not limited to intellectual property
            rights; or (6) any overt harmful act toward any other user of the
            Site with whom you connected via the Site. Notwithstanding the
            foregoing, we reserve the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us, and you agree to cooperate, at your
            expense, with our defense of such claims. We will use reasonable
            efforts to notify you of any such claim, action, or proceeding which
            is subject to this indemnification upon becoming aware of it.
          </p>

          {/* point 23 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="userdata">
            23. USER DATA
          </p>
          <p className="my-4">
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </p>

          {/* point 24 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="electronic">
            24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </p>
          <p className="my-4">
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SITE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>

          {/* point 25 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="california">
            25. CALIFORNIA USERS AND RESIDENTS
          </p>
          <p className="my-4">
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.{" "}
          </p>

          {/* point 26 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="misc">
            26. MISCELLANEOUS
          </p>
          <p className="my-4">
            {" "}
            These Terms of Use and any policies or operating rules posted by us
            on the Site or in respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.{" "}
          </p>

          {/* point 27 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="profit">
            27. PROFIT SHARING
          </p>
          <p className="my-4">
            All Users creating a competition agree to share their profits with
            users who voted in their favor. The Amount to be shared will be no
            lesser than the amount the "Voters" contributed backing you. Compete
            AE Inc. will share their profits with voters who chose an outcome of
            no and contributed monetarily to the competition.{" "}
          </p>

          {/* point 28 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="cop">
            28. CONDITIONS OF PARTICIPATION
          </p>
          <p className="my-4">
            By entering a COMPETITION, entrants agree to be bound by these Terms
            of Use, the Privacy Policy, and the Rules and the decisions of
            Compete AE, which shall be final and binding in all respects. The
            Company, at its sole discretion, may disqualify any entrant from a
            COMPETITION, refuse to award benefits or prizes and require the
            return of any prizes, if the entrant engages in conduct or otherwise
            utilizes any information the Company deems to be improper, unfair or
            otherwise adverse to the operation of the COMPETITION or is in any
            way detrimental to other entrants. These Terms prohibit entering a
            COMPETITION if the entrant is: • Except as otherwise stated in the
            Eligibility section above, an Employee of the Company or an
            Immediate Family Member of such Employee; • An employee or operator
            of any online gaming operator sy site including any that charges
            entrance fees or offers prizes, and any Immediate Family Member of
            any such person; • An employee of a sponsor, consultant, or supplier
            of the Company or any other COMPETITION provider that has access to
            Pre-Release Data or otherwise receives an advantage in the entrant's
            participation in a COMPETITION; • An employee, operator or
            consultant to a sports governing body where such employee, operator
            or consultant is prohibited from participating in applicable
            COMPETITIONs by such governing body; • Any person prohibited from
            participating pursuant to court order; • Any entrant who has
            knowingly received Pre-Release Data or any other non-public
            information that provides an advantage in a COMPETITION from any
            person who is prohibited from entering a COMPETITION as provided in
            these Terms. In addition, conduct that would be deemed improper also
            includes, but is not limited to: • Falsifying personal information
            required to enter a COMPETITION or claim a prize; • Engaging in any
            type of financial fraud including unauthorized use of credit
            instruments to enter a COMPETITION or claim a prize; • Colluding
            with any other individual(s) or engaging in any type of syndicate
            play; • Any violation of Rules or the Terms of Use; • Using a single
            Account to participate in a COMPETITION on behalf of multiple
            entrants or otherwise collaborating with others to participate in
            any COMPETITION; • Using automated means (including but not limited
            to harvesting bots, robots, parser, spiders or screen scrapers) to
            obtain, collect or access any information on the Website or of any
            User for any purpose. • Any type of bonus abuse, abuse of the
            refer-a-friend program, or abuse of any other offers or promotions;
            • Tampering with the administration of a COMPETITION or trying to in
            any way tamper with the computer programs or any security measure
            associated with a COMPETITION; • Obtaining other entrants
            information and spamming other entrants; or • Abusing the Website in
            any way. Users further acknowledge that the forfeiture and/or return
            of any prize shall in no way prevent Compete AE from pursuing
            criminal or civil proceedings in connection with such conduct. By
            entering into a COMPETITION or accepting any prize, entrants,
            including but not limited to the winner(s), agree to indemnify,
            release and to hold harmless Compete AE, its parents, subsidiaries,
            affiliates and agents, as well as the officers, directors,
            employees, shareholders and representatives of any of the foregoing
            entities (collectively, the "Released Parties"), its parents,
            subsidiaries, affiliates and divisions, and their respective
            directors, officers, employees, agents and representatives from any
            and all liability, claims or actions of any kind whatsoever,
            including but not limited to injuries, damages, or losses to persons
            and property which may be sustained in connection with participation
            in the COMPETITION, the receipt, ownership, use or misuse of any
            prize or while preparing for, participating in and/or travelling to
            or from any prize related activity, as well as any claims based on
            publicity rights, defamation, or invasion of privacy. Compete AE
            may, in its sole and absolute discretion, require an Authorized
            Account Holder to execute a separate release of claims similar to
            the one listed above in this Paragraph as a condition of being
            awarded any prize or receiving any payout. Compete AE is not
            responsible for: any incorrect, invalid or inaccurate entry
            information; human errors; postal delays/postage due mail; technical
            malfunctions; failures, including public utility or telephone
            outages; omissions, interruptions, deletions or defects of any
            telephone system or network, computer online systems, data, computer
            equipment, servers, providers, or software (including, but not
            limited to software and operating systems that do not permit an
            entrant to participate in a COMPETITION), including without
            limitation any injury or damage to any entrant's or any other
            person's computer or video equipment relating to or resulting from
            participation in a COMPETITION; inability to access the Website, or
            any web pages that are part of or related to the Website; theft,
            tampering, destruction, or unauthorized access to, or alteration of,
            entries and/or images of any kind; data that is processed late or
            incorrectly or is incomplete or lost due to telephone, postal
            issues, computer or electronic malfunction or traffic congestion on
            telephone lines or transmission systems, or the Internet, or any
            service provider's facilities, or any phone site or website or for
            any other reason whatsoever; typographical, printing or other
            errors, or any combination thereof. Compete AE is not responsible
            for incomplete, illegible, misdirected or stolen entries. If for any
            reason a COMPETITION is not capable of running as originally
            planned, or if a COMPETITION, computer application, or website
            associated therewith (or any portion thereof) becomes corrupted or
            does not allow the proper entry to a COMPETITION in accordance with
            the Terms of Use or applicable Rules, or if infection by a computer
            (or similar) virus, bug, tampering, unauthorized intervention,
            actions by entrants, fraud, technical failures, or any other causes
            of any kind, in the sole opinion of Compete AE corrupts or affects
            the administration, security, fairness, integrity, or proper conduct
            of a COMPETITION, the Company reserves the right, at its sole
            discretion, to disqualify any individual implicated in such action
            and/or to cancel, terminate, extend, modify or suspend the
            COMPETITION, and select the winner(s) from all eligible entries
            received. If such cancellation, termination, modification or
            suspension occurs, notification will be posted on the Website. ANY
            ATTEMPT BY AN ENTRANT OR ANY OTHER INDIVIDUAL TO DELIBERATELY DAMAGE
            THE WEBSITE OR UNDERMINE THE LEGITIMATE OPERATION OF ANY COMPETITION
            IS A VIOLATION OF CRIMINAL AND/OR CIVIL LAWS AND SHOULD SUCH AN
            ATTEMPT BE MADE, COMPETE AE RESERVES THE RIGHT TO SEEK DAMAGES AND
            OTHER REMEDIES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED
            BY LAW. All entries become the property of Compete AE and will not
            be acknowledged or returned. To be eligible to enter any COMPETITION
            or receive any prize, the Authorized Account Holder may be required
            to provide Compete AE with additional documentation and/or
            information to verify the identity of the Authorized Account Holder,
            and to provide proof that all eligibility requirements are met. In
            the event of a dispute as to the identity or eligibility of an
            Authorized Account Holder, Compete AE will, in its sole and absolute
            discretion, utilize certain information collected by Compete AE to
            assist in verifying the identity and/or eligibility of such
            Authorized Account Holder. Participation in each COMPETITION must be
            made only as specified in the Terms of Use. Failure to comply with
            these Terms of Use will result in disqualification and, if
            applicable, prize forfeiture. Where legal, both entrants and winners
            consent to the use of their name, voice, and likeness/photograph in
            and in connection with the development, production, distribution
            and/or exploitation of any COMPETITION or the Website. Winners agree
            that from the date of notification by Compete AE of their status as
            a potential winner and continuing until such time when Compete AE
            informs them that they no longer need to do so that they will make
            themselves available to Compete AE for publicity, advertising, and
            promotion activities.
          </p>

          {/* point 29 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="game">
            29. GAME ENTRY
          </p>
          <p className="my-4">
            Users will be able to visit the Website and view the Competitions
            available to play and place a competition (the "Competition" or
            collectively, the "Competitions"). When you select to participate in
            a Competition and complete the entry process, the listed amount of
            US dollars will be debited from your Compete AE Account.
          </p>

          {/* point 30 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="pwp">
            30. PAYMENT AND WITHDRAWAL OF PRIZES
          </p>
          <p className="my-4">
            {" "}
            Before making any payment, Compete AE may require that an entrant
            complete and execute an affidavit of eligibility in which, among
            other things, the entrant is required to represent and warrant that
            the entrant is eligible to participate in a Game, is otherwise in
            compliance with this Agreement and, potentially, is required to
            provide documentation or proof of eligibility and compliance. If
            Compete AE requests that an entrant completes and executes such an
            affidavit and the entrant fails to do so within seven (7) days, or
            Compete AE otherwise determines that the entrant does not meet the
            eligibility requirements or is not in compliance with these Terms,
            Compete AE reserves the right to terminate the entrant's account and
            withhold or revoke the awarding of any prizes associated with such
            account. In such a situation, Compete AE may pay out any withheld or
            revoked prizes to the other entrants in the relevant Game in a
            manner consistent with the Rules of the Game. Entrants may withdraw
            their cash prize awards as well as cash deposits by using the
            "Withdrawal" option on the Website. Entrants may be requested to
            complete an affidavit of eligibility and a liability/publicity
            release (unless prohibited by law) and/or appropriate tax forms and
            forms of identification including but not limited to a Driver's
            License, Proof of Residence, and/or any information relating to
            payment/deposit accounts as reasonably requested by Compete AE in
            order to complete the withdrawal of prizes. Failure to comply with
            this requirement may result in disqualification and forfeiture of
            any prizes. Disqualification or forfeiture of any prizes may also
            occur if it is determined any such entrant did not comply with these
            Terms of Use in any manner. Checks for withdrawal requests are
            processed within 14 business days and are sent via U.S. Mail.
            Promotional deposits, credits, and other bonuses may not be
            withdrawn from a Compete AE Account unless appropriate terms of the
            promotion are achieved first by the user. All taxes associated with
            the receipt of any prize are the sole responsibility of the winner.
            You consent to receive your Compete AE related tax information
            electronically, which information will be securely hosted by Compete
            AE and accessible by you through your player account page for at
            least seven (7) calendar years following the year to which the
            information relates. Your electronic tax information will be
            furnished on a downloadable PDF, which you should be able to access
            and print using a standard PDF reader. You may contact Compete AE at{" "}
            <span className="text-[#EDCB76]">support@CompeteAE.com</span> if you
            cannot access your electronic tax information or to request a paper
            copy of your tax information. If you wish to revoke your consent to
            receive your tax information electronically, you may contact Compete
            AE at <span className="text-[#EDCB76]">support@CompeteAE.com </span>
            to arrange for all of your post-revocation tax information to be
            provided to you in paper form. In order to update your recipient
            information, please use the instructions for changing your personal
            information in the Privacy Policy. In the event that the awarding of
            any prizes to winners of Games is challenged by any legal authority,
            Compete AE reserves the right in its sole discretion to determine
            whether or not to award such prizes. No substitution or transfer of
            prize is permitted, except that Compete AE reserves the right to
            substitute a prize of equal value or greater if the advertised prize
            is unavailable. All prizes are awarded "as is" and without warranty
            of any kind, express or implied (including, without limitation, any
            implied warranty of merchantability for a particular purpose).
            Compete AE, Inc. will take appropriate steps to prevent unauthorized
            withdrawals from consumer Accounts. Users are subject to an
            automated Knowledge Base Authentication ("KBA") verification process
            provided whenever a user attempts to withdraw funds. This process
            asks a series of at least four multiple choice questions about the
            person's life, such as known relatives, previous addresses, and to
            enter their last 4 digits of their Tax ID or Social Security Number.
            In the event of a dispute as to the identity of a customer, Compete
            AE, Inc. can and does require its users to produce a copy of their
            driver's license, credit cards, utility bills or passport for
            identity verification. If a customer cannot validate their identity
            and account, Compete AE, Inc. will not release the funds from the
            account. Once per month, Compete AE, Inc. shall reconcile all
            liabilities owed to users and the sum of funds or credits in the
            Segregated Account, the PayPal Account, and credits due to Compete
            AE, Inc. from credit card processors as of the end date of the
            immediately preceding month. Players who believe that funds held by
            or their accounts with Compete AE, Inc. have been misallocate,
            compromised or otherwise mishandled, may register a complaint with
            Compete AE, Inc. online by e-mailing
            <span className="text-[#EDCB76]"> support@CompeteAE.com</span> or in
            writing to 293 N Broadway Yonkers NY 10701. Compete AE, Inc. shall
            use its best efforts to respond to such complaints within five (5)
            days. If Compete AE, Inc. determines that the relief requested in
            the complaint will not be granted, its response will set forth the
            specific reasons therefore. If more information is required for
            Compete AE Inc. to process the complaint, the response will note the
            form and nature of the necessary additional information needed. A
            player can request to withdraw funds from their account at any time.
            Requests for withdrawal will be honored by the later of five
            business days or ten business days of submission of any tax
            reporting paperwork required by law, unless Compete AE, Inc.
            believes in good faith that the player engaged in either fraudulent
            conduct or other conduct that would put Compete AE, Inc. in
            violation of New Jersey state law. A request for withdrawal will be
            considered honored if it is processed by Compete AE, Inc. but
            delayed by a payment processor, credit card issuer, or by the
            custodian of a financial account. Players can also permanently close
            their account at any time for any reason through the Compete AE,
            Inc. customer service department.
          </p>

          {/* point 31 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="price">
            31. PRIZES
          </p>
          <p className="my-4">
            {" "}
            Subject to the Company verifying your compliance with the Terms,
            Rules and other conditions of participation, at the conclusion of
            each Competition, the Company shall use commercially reasonable
            efforts to award the winnings and/or prizes within a reasonable
            amount of time. We reserve the right to cancel Competitions at any
            time.{" "}
          </p>

          {/* point 32 of T&C */}
          <p className="mt-8 text-[#EDCB76] font-bold" id="contact">
            32. CONTACT US
          </p>
          <p className="my-8">
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us at:
          </p>
          <p className="leading-loose font-semibold">
            Compete AE Inc <br /> 293 N Brodway <br /> Yonker NY 10701 <br />{" "}
            United states <br /> Phone: 9145399209 <br /> support@competeae.com
          </p>
        </div>

        {/*//Notes: footer */}
        {/* <FooterSection /> */}
      </div>
    </div>
  );
}

export default TC;
