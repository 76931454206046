import React from "react";
import moment from "moment";

import bg1 from "../../../assets/event/green.png";
import bg2 from "../../../assets/event/purple.png";
import bg3 from "../../../assets/event/blue.png";
import bg4 from "../../../assets/event/red.png";

import Overlay from "../../overlay/Overlay";
import "./evant_card.css";

const EventCards = ({ categoryName , categoryIndex, event, index, navigate , formattedStartDate  }) => {
  // console.log("eventDataeventDataeventData",eventData)
  const eventDetailsFunc = (event) => {
    navigate("/eventDetailspage", {
      state: { categoryName, eventData: event },
    });
  };
  const date = `${moment(event?.startDate).format(
    "ddd DD/MM/yyyy "
  )} to ${moment(event?.endDate).format("ddd DD/MM/yyyy")}`;
  // Define an array of colors
  // const colors = ["#39A805", "#B624DA", "#CC2933", "#59A2F6", "#5F00FA"];
  // // Determine the color based on the index
  // const buttonColor = colors[Math.floor(index / 2) % colors.length];
  const colors = ["#F1AA34", "#B624DA"]; // Assuming the purple shade is #800080

// Determine the color based on the index (alternating between the two colors)
const buttonColor = colors[index % 2];
  const bg = [bg1, bg2, bg3, bg4];

  return (
    <div
      key={categoryIndex}
      onClick={() => eventDetailsFunc(event)}
      className="event-card"
    >
      <img
        className="position-absolute left-0 opacity-50 w-full"
        src={bg[Math.floor(index / 2) % bg.length]}
        alt=""
      />
      <div
        className="event-card-section"
        style={{ backgroundImage: `url(${event.images[0]})` }}
      >
        <div className="event-card-content">
          <div>
            <h1 className="event-title text-white">{event.title}</h1>
           
          </div>
          <div className="d-flex flex-col"> 
            <div className="event-details">
              <div className="event-details-sub">
                <span className="event-price">
                  From-${event.minAmount ?? 0} on {formattedStartDate}
                </span>
                {/* <span className="event-item">
                  {event?.itemIncluded[0]?.title}:{" "}
                  {event?.itemIncluded[0]?.description.slice(0, 14)}...
                </span> */}
              </div>
              <p className="event-description">
              Desc: {event.description.slice(0, 25)}...
            </p>
              {/* <p className="event-date event-price">Date: {date}</p> */}
            </div>
            <button
              className="join-btn"
              style={{ backgroundColor: buttonColor }}
            >
              Join in
            </button> 
          </div>
        </div>
      </div>
      {/* <Overlay zIndex={-1} /> */}
    </div>
  );
};

export default EventCards;
