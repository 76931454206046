import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./eventDetails.css";

const EditionPhotoshoot = ({ data }) => {
  // console.log("🚀 ~ EditionPhotoshoot ~ data:", JSON.stringify(data, 0, 2))

  const images = data?.images || [];

  return (
    <Container className="text-white container-9">
      <h1 className="h1 fw-700 fs-33 mb-0 eventGalleryTitle">{data?.title}</h1>
      <Row className="gap-4">
        <div className="d-flex gap-4">
          {/* <p className="eventStarCount d-flex gap-2 fw-500 align-items-center">
            <img src={StarIcon} alt="starIcon" width={20} height={20} />
            4.98(331)
          </p> */}
          <Link className="text-white fw-500 eventState">{data?.location}</Link>
        </div>

        <div class="container editionPhotosSection">
          <div className="grid grid-cols-7 gap-2">
            {images.length > 0 && (
              <div className=" col-span-2">
                <img
                  // src={PhotoshootImg1}
                  src={images[0]}
                  alt="PhotoshootImg"
                  className="w-full h-fit aspect-[350/410] rounded-lg"
                />
              </div>
            )}
            {images.length > 1 && (
              <div className="col-span-2">
                <img
                  src={images[1]}
                  alt="PhotoshootImg"
                  className="w-full h-fit aspect-[350/410] rounded-lg"
                />
              </div>
            )}
            {images.length > 2 && (
              <div className=" flex flex-col gap-2">
                <div class="w-100  ">
                  <img
                    src={images[2]}
                    alt="PhotoshootImg"
                    className=" w-full h-fit aspect-[164/223] rounded-lg"
                  />
                </div>
                <div class="w-100 ">
                  <img
                    src={images[3]}
                    alt="PhotoshootImg"
                    className=" w-full h-fit aspect-[164/163] rounded-lg"
                  />
                </div>
              </div>
            )}
            {images.length > 4 && (
              <div className=" col-span-2">
                <img
                  src={images[4]}
                  alt="PhotoshootImg"
                  className=" w-full h-fit aspect-[350/410] rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default EditionPhotoshoot;
