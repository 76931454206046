import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";

import EventsPriceCard from "./EventsPriceCard";
import MobileWhatYouDo from "./MobileWhatYouDo";

import cardBg from "../../../assets/event/Ellipse2.png";

import "./eventDetails.css";

const ExperienceHosted = ({ data, setView, setShowPayment }) => {
  const [mobileWhatDo, setMobileWhatDo] = useState(false);
  const [diff, setDiff] = useState(0);

  useEffect(() => {
    if (moment(data?.endDate).diff(data?.startDate, "day") > 0) {
      setDiff(`${moment(data?.endDate).diff(data?.startDate, "day")} Days`);
    } else {
      setDiff(`${moment(data?.endDate).diff(data?.startDate, "hour")} hr`);
    }
  }, [data?.startDate, data?.endDate]);

  /* mobile screen */
  useEffect(() => {
    const handleResize = () => {
      setMobileWhatDo(window.innerWidth <= 575);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="pt-5 pb-5 eventsTopSec">
      <Container className="d-flex flex-row container-9 text-white hostedBySec">
        <div className="col-8 hostByCol1">
          <div className="d-flex items-center">
            <div className="col-9">
              <h2 className="experienceHost textSecondary mobile_size fw-700">
                Experience hosted by {data?.creatorData?.fullName}
              </h2>
              <p className="hostTime fw-light pt-1">
                {/* 1 hour· Hosted in English */}
                {diff} - Hosted in English
              </p>
            </div>
            <div className="col-4 hostProfile">
              <img
                src={data?.creatorData?.profileImage}
                alt="hostProfile"
                className="rounded-full !object-cover"
                style={{ width: "55px", height: "55px" }}
              />
            </div>
          </div>
          <div className="bottomHrLine w-90"></div>
          <div className="d-flex flex-col gap-4">
            {data?.activityNotes?.map((item, index) => (
              <div key={index} className="d-flex flex-col gap-3">
                <div className="d-flex items-center">
                  <img
                    src={item.icon}
                    alt="lucideStars"
                    width={52}
                    height={52}
                    className="rounded-full"
                  />
                  <div className="pl-4 ratedHost">
                    <h2 className="textSecondary text-lg">{item?.title}</h2>
                    <p>{item?.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="bottomHrLine w-90"></div>
          <div className="whatYouDo pt-0">
            <h1 className="fw-700 fs-33 textSecondary">What you'll do?</h1>
            <p className="fw-400">{data?.activityDescription}</p>
            {mobileWhatDo && <MobileWhatYouDo />}
          </div>
        </div>
        <div className="col-4 hostByCol2 relative">
          <EventsPriceCard data={data} setView={setView} />
          <img className="absolute top-0 zindex_img" src={cardBg} alt="" style={{ pointerEvents: "none" }} />
        </div>
      </Container>
    </div>
  );
};

export default ExperienceHosted;
