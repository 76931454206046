import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://stag.competeae.com/",
    prepareHeaders: (headers, {getState, endpoint}) => {
      // Conditionally add the token for the PaypalPayment endpoint
      if (
        endpoint === "PaypalPayment" ||
        endpoint === "ConfirmPayment" ||
        endpoint == "getWallet" ||
        endpoint == "JoinTeam" ||
        endpoint === "JoinBoth" ||
        endpoint == "JoinSurvivour" ||
        endpoint == "getEventById" ||
        endpoint == "GetEventTeam" ||
        endpoint == "AddToTeam"
      ) {
        let token = localStorage.getItem("loginData")
        token = JSON.parse(token)
        console.log("🚀 ~ checkAuth ~ data:", token) // Get the token from local storage or any other source
        if (token) {
          headers.set("Authorization", `Bearer ${token?.accessToken}`)
        }
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    getEventList: builder.query({
      query: () => {
        const url = "api/v1/events/find"
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    getEventById: builder.query({
      query: ({id}) => {
        const url = `api/v1/events/find-one/${id}`
        console.log("🚀 ~ url:", url)
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    signIn: builder.mutation({
      query: (body) => {
        const url = `login`
        console.log("🚀 ~ url:", body)
        return {
          url: url,
          method: "POST",
          body: body,
        }
      },
    }),
    signUp: builder.mutation({
      query: (body) => {
        const url = `users/check-user`
        console.log("🚀 ~ url:", body)
        return {
          url: url,
          method: "POST",
          body: body,
        }
      },
    }),
    SignUpWithImage: builder.mutation({
      query: (body) => {
        console.log("🚀 ~ body:", body)
        const url = `users`
        return {
          url: url,
          method: "POST",
          body: body,
        }
      },
    }),
    imageUpload: builder.mutation({
      query: (payload) => {
        console.log("🚀 ~ payload:", payload)
        return {
          url: `upload`,
          method: "POST",
          body: payload,
        }
      },
      //   invalidatesTags: ["ImageUpload"],
    }),
    VerifyOTP: builder.mutation({
      query: (data) => {
        const url = `users/check-otp`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    CheckUser: builder.mutation({
      query: (data) => {
        const url = `api/v1/user/phone`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    ResendOtp: builder.mutation({
      query: (data) => {
        const url = `users/resend-otp`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    PaypalPayment: builder.mutation({
      query: (data) => {
        const url = `payments/paypal`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    ConfirmPayment: builder.mutation({
      query: (data) => {
        const url = `payments/paypal/accept-paypal-payment`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    getWallet: builder.query({
      query: (data) => {
        const url = `api/v1/user/wallet`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    JoinTeam: builder.mutation({
      query: (data) => {
        const url = `api/v1/events/join/team`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    JoinSurvivour: builder.mutation({
      query: (data) => {
        const url = `api/v1/events/join/survivor`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    JoinBoth: builder.mutation({
      query: (data) => {
        const url = `api/v1/events/join/both`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    subscribe: builder.mutation({
      query: (data) => {
        const url = `api/v1/subscribe`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    GetEventTeam: builder.query({
      query: ({id}) => {
        const url = `api/v1/events/get-event-team/${id}`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    AddToTeam: builder.query({
      query: (data) => {
        const url = `api/v1/events/add-to-team?eventId=${data?.eventId}&participantId=${data?.participantId}`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    // Add more endpoints here
  }),
})

export const {
  useGetEventListQuery,
  useGetEventByIdQuery,
  useSignInMutation,
  useSignUpMutation,
  useSignUpWithImageMutation,
  useImageUploadMutation,
  useVerifyOTPMutation,
  useAddToTeamQuery,
  useCheckUserMutation,
  useResendOtpMutation,
  usePaypalPaymentMutation,
  useConfirmPaymentMutation,
  useGetWalletQuery,
  useJoinTeamMutation,
  useJoinSurvivourMutation,
  useJoinBothMutation,
  useGetEventTeamQuery,
  useSubscribeMutation
} = apiSlice
