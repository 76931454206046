import React, {useState} from "react"
import {useEffect} from "react"
import {Modal} from "react-bootstrap"

import Login from "./Login"
import SignUp from "./SignUp"
import UserDetails from "./UserDetails"
import VerifyOtp from "./VerifyOtp"

const AuthPopUp = ({setView, view, setAuthorisedUser, refetch}) => {
  const [usrname, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState()
  const [imageUrl, setImageUrl] = useState(null)
  const [fullName, setFullName] = useState("")
  const [referrelCode, setReferralCode] = useState("")
  const [uniqueCode, setUniqueCode] = useState("")

  useEffect(() => {
    console.log("imimageUrlage", imageUrl)
  }, [imageUrl])

  return (
    <Modal
      show={view != 0}
      // onHide={() => setView(0)}
      centered
      className="loginContainer"
    >
      {view == 1 ? (
        <Login setView={setView} setAuthorisedUser={setAuthorisedUser} />
      ) : view == 2 ? (
        <SignUp
          setView={setView}
          setUsername={setUsername}
          usrname={usrname}
          setEmail={setEmail}
          email={email}
          setPassword={setPassword}
          password={password}
        />
      ) : view == 3 ? (
        <UserDetails
          setView={setView}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          setPassword={setPassword}
          setUsername={setUsername}
          usrname={usrname}
          password={password}
          email={email}
          setEmail={setEmail}
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          setReferralCode={setReferralCode}
          referrelCode={referrelCode}
          setFullName={setFullName}
          setUniqueCode={setUniqueCode}
        />
      ) : view == 4 ? (
        <VerifyOtp
          setView={setView}
          imageUrl={imageUrl}
          usrname={usrname}
          password={password}
          email={email}
          phoneNumber={phoneNumber}
          uniqueCode={uniqueCode}
          fullName={fullName}
          referrelCode={referrelCode}
          refetch={refetch}
        />
      ) : (
        ""
      )}
    </Modal>
  )
}

export default AuthPopUp
