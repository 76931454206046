import React, {createContext, useContext, useState} from "react"

// Create the context
const AuthContext = createContext()

// Create a provider component
export const AuthProvider = ({children}) => {
  const [view, setView] = useState(0)
  const [showPayment, setShowPayment] = useState(false)
  const [isAuthPopupOpen, setIsAuthPopupOpen] = useState(false)

  const openAuthPopup = (view = 1) => {
    setView(view)
    setIsAuthPopupOpen(true)
  }
  const closeAuthPopup = () => {
    setIsAuthPopupOpen(false)
  }
  return (
    <AuthContext.Provider
      value={{
        view,
        openAuthPopup,
        closeAuthPopup,
        isAuthPopupOpen,
        setView,
        setShowPayment,
        showPayment,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext)
