

import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const FrequentlyAccordion = () => {
  const [activeSections, setActiveSections] = useState([
    {
      "heading": "Account Management",
      "isToggle": false,
      "faq": [
        {
          "question": "How do I create an account?",
          "answer": "To create an account, click on the 'Sign Up' button on our homepage and fill in the required information. Make sure to provide a valid email address for account verification.",
          "isToggle": false
        },
        {
          "question": "What should I do if I forget my password?",
          "answer": "If you forget your password, click on the 'Forgot Password' link on the login page and follow the instructions to reset your password.",
          "isToggle": false
        },
        {
          "question": "How do I close my account?",
          "answer": "If you wish to close your account, please contact our customer support team for assistance.",
          "isToggle": false
        }
      ]
    },
    {
      "heading": "Payment and Financial Information",
      "isToggle": false,
      "faq": [
        {
          "question": "What payment methods are accepted?",
          "answer": "We accept various payment methods including credit/debit cards, e-wallets, and bank transfers.",
          "isToggle": false
        },
        {
          "question": "How can I withdraw my winnings?",
          "answer": "To withdraw your winnings, go to the 'Withdrawal' section of your account, choose your preferred method, and enter the amount you wish to withdraw.",
          "isToggle": false
        },
        {
          "question": "Are there any fees for depositing or withdrawing?",
          "answer": "We do not charge any fees for deposits. However, there may be fees for withdrawals depending on the method used.",
          "isToggle": false
        },
        {
          "question": "Does CompeteAE take a percentage from my contribution if I win?",
          "answer": "No, CompeteAE does not take a percentage from the winner's contribution. Winners receive 100% of their initial contribution back, plus a percentage of the total contributions from the participants who did not win and contributions from Voters against them.",
          "isToggle": false
        },
        {
          "question": "How does CompeteAE make money?",
          "answer": "CompeteAE generates most of its revenue by taking a 10% share from the contributions of participants who do not win in a specific competition.",
          "isToggle": false
        }
      ]
    },
    {
      "heading": "Competition Rules and Integrity",
      "isToggle": false,
      "faq": [
        {
          "question": "Why must I pay for my competitions beforehand even if I'm successful?",
          "answer": "Pre-payment for competitions ensures commitment and fairness among all participants and expedites the process of prize distribution. It also enhances motivation through the endowment effect and addresses risk aversion, fostering a more competitive and engaging environment.",
          "isToggle": false
        },
        // ... other questions and answers for Competition Rules and Integrity
      ]
    },
    {
      "heading": "Security and Compliance",
      "isToggle": false,
      "faq": [
        {
          "question": "Is it safe to play on your site?",
          "answer": "Yes, we prioritize the security of our users. Our site uses advanced encryption technology to protect personal and financial information.",
          "isToggle": false
        },
        {
          "question": "Are there any restrictions for players from certain countries?",
          "answer": "Yes, players from certain countries may be restricted due to legal reasons. Please refer to our 'Terms and Conditions' for a list of restricted countries.",
          "isToggle": false
        }
      ]
    },
    {
      "heading": "User Support and Settings",
      "faq": [
        {
          "question": "How can I contact customer support?",
          "answer": "Our customer support team is available 24/7 via email. Visit our 'Contact Us' page for more details.",
          "isToggle": false
        }
      ]
    }
  ]);

  const toggleSection = (section) => {
    const obj = [...activeSections];
    obj[section].isToggle = !obj[section].isToggle;
    setActiveSections(obj);
  };

  const toggleFAQ = (sectionKey, faqIndex) => {
    const updatedSections = activeSections.map((section) => {
      if (section.key === sectionKey) {
        const updatedFAQs = section.faq.map((faq, index) => {
          if (index === faqIndex) {
            return { ...faq, isToggle: !faq.isToggle };
          }
          return faq;
        });
        return { ...section, faq: updatedFAQs };
      }
      return section;
    });
    setActiveSections(updatedSections);
  };
  const redirectToAppStore = () => {
    window.location.href =
      "https://apps.apple.com/in/app/competeae/id6444800738"; // Replace with your App Store URL
  };

  return (
    <div className="container">
      {/* <div className="mx-auto mt-5 justify-content-center d-flex">
       <button className="cta-btn" onClick={redirectToAppStore}>
                Download App Now
              </button>
      </div> */}
    <div className="row justify-content-center mt-lg-3 mt-2 mb-lg-5 mb-2">
      {/* ... Your title and other content */}
    </div>
    <div className="row justify-content-between mt-lg-3 mt-2 mb-5">
   
      <div className="col-lg-12 col-md-12 col-sm-12">
      <h2 class="mt-0 mb-lg-5 mb-2 container-8-2 text-center mob_fon2t">Frequently Asked Questions  (FAQ)</h2>
        {activeSections.map((section, sectionIndex) => (
          <div className="col-lg-12 col-md-12 col-sm-12 bor_faq mt-3" key={sectionIndex}>
            <Accordion>
              <Accordion.Item eventKey={sectionIndex}>
              <Accordion.Header
                as={Card.Header}
                onClick={() => toggleSection(sectionIndex)}
              >
                {section.heading}
              </Accordion.Header>
                <Accordion.Body>
                  <Accordion.Item>
                    {section.faq.map((faq, faqIndex) => (
                      <Accordion>
                      <Accordion.Item eventKey={faqIndex}>
                        <Accordion.Header
                          onClick={() => toggleFAQ(section.key, faqIndex)}
                          aria-controls={`faq${sectionIndex}-${faqIndex}`}
                          aria-expanded={faq.isToggle}
                          className="btn-link"
                        >
                          {faq.question}
                        </Accordion.Header>
                        <Accordion.Body
                          eventKey={`faq${sectionIndex}-${faqIndex}`}
                        >
                          <Card.Body>{faq.answer}</Card.Body>
                        </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))}
                  </Accordion.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
}

export default FrequentlyAccordion;