import React from "react"
import {Col, Container, Row} from "react-bootstrap"

import PhotoshootImg1 from "../../../assets/icons/photoshoot1.svg"
import PhotoshootImg2 from "../../../assets/icons/photoshoot2.svg"
import PhotoshootImg3 from "../../../assets/icons/photoshoot3.svg"
import PhotoshootImg4 from "../../../assets/icons/photoshoot4.svg"
import PhotoshootImg5 from "../../../assets/icons/photoshoot5.svg"

import "./eventDetails.css"

const MobileWhatYouDo = () => {
  return (
    <Container className="text-white container-9">
      <div class="row align-items-center">
        <div
          class="col-4 d-flex flex-column gap-2 justify-content-between"
          //   style={{ width: "14.8%" }}
        >
          <div class="w-98">
            <img src={PhotoshootImg3} alt="PhotoshootImg" />
          </div>
          <div class="w-98">
            <img src={PhotoshootImg4} alt="PhotoshootImg" />
          </div>
        </div>
        <div
          class="col-8"
          // style={{ width: "28.1%" }}
        >
          <img src={PhotoshootImg5} alt="PhotoshootImg" />
        </div>
      </div>
      {/* <button class="featureDetailsBtn mt-4 fs-5 fw-700">
        Show all feature details
      </button> */}
    </Container>
  )
}

export default MobileWhatYouDo
