import React from "react";

import UpcomingEventSearch from "../../components/molecules/upcomingEvent/UpcomingEventSearch";

const EventsPage = () => {
  return (
    <div className="pt-5 eventBG">
      <div className="bg_clr mt-lg-4 mt-2">
        <div className="container">
          <UpcomingEventSearch />
        </div>
      </div>
    </div>
  );
};

export default EventsPage;
