import React, { useMemo } from "react";
import { Container } from "react-bootstrap";

import CompetInfo from "../../../assets/images/competInfo.png";
import JoinCompetition from "../../../assets/images/joinCompetition.png";
import Logo from "../../../assets/images/lgo.svg";
import AppStore from "../../../assets/icons/appStore.svg";
import GooglePay from "../../../assets/icons/googlePay.svg";

import "./confirmation.css";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
import ConfirmationTable from "./confirmationTable";
import {
  useAddToTeamQuery,
  useGetEventListQuery,
  useGetEventTeamQuery,
} from "../../../Store/api/ApiSlice";
import Overlay from "../../overlay/Overlay";
import EventCreationCard from "../upcomingEvent/EventCreationCard";

const ConfirmationDetails = () => {
  const location = useLocation();
  const user = location.state;
  console.log("user?.data?.eventData", user?.data, location);
  const { data, isSuccess, refetch } = useGetEventTeamQuery({
    id: user?.data?.eventData?.eventId,
  });

  const { data: eventData } = useGetEventListQuery();


  console.log("🚀 ~ ConfirmationDetails ~ user:", user);
  let startDate = moment(user?.data?.eventData?.startDate).format(
    "MM/YY/YYYY hh:mm"
  );
  let endDate = moment(user?.data?.eventData?.endDate).format(
    "MM/YY/YYYY hh:mm"
  );
  // const { data: eventData, isSuccess } = useGetEventListQuery();
  const { latestEvent, groupEvent, latestEventDate, formattedStartDate } =
    useMemo(() => {
      // console.log(
      //   "🚀 ~ const{latestEvent,groupEvent,startDate,endDate}=useMemo ~ s:",
      //   s
      // )

      return {
        groupEvent: eventData?.data[0]?.groupedEvents.filter(
          (data) => data.category.name === user.categoryName
        ),
      };
    }, [eventData, isSuccess]);

  return (
    <div className="container eventDetailsContainer pt-0 pb-5">
      <Container className="text-white container-9">
        <div>
          <h1 className="display-4 font-weight-bold text-center AELiveTitle textPrimary ">
            Thanks For Joining
          </h1>
          <p className="fastToComplete text-center mb-lg-0 mb-2 gold fs-2">
            {user?.data?.eventData?.title}
          </p>

          <div
            className="competNameSection d-flex  justify-end event_pad mt-2"
            style={{ backgroundImage: `url(${user?.data?.eventData?.image})` }}
          ></div>
        </div>
        <div>
          <div className="itsOfficialSec">
            <div className="itsOfficial d-flex flex-col">
              <h1 className="fw-700 fs-20 textPrimary mb-0  mobile_size mt-4">
                It’s official. You’re Competing
              </h1>
              <p className="fw-400 confirmation_para ">
                You’ve joined a Compete AE Competition and now have the
                opportunity to <br className="d-lg-block d-none" /> inspire
                others, have fun and Earn some Cash.
              </p>
            </div>
          </div>
          <div className="">
            {user?.data?.team && (
              <h3 className="font-medium gold">
                {/* text-[#3ea2ff] */}
                Team participant status :{" "}
                <span className="text-white">{user?.data?.team?.status}</span>
              </h3>
            )}
            {user?.data?.survivor && (
              <h3 className="font-medium gold">
                Survivour participant status:{" "}
                <span className="text-white">
                  {user?.data?.survivor?.status}
                </span>
              </h3>
            )}
            <h3 className="font-medium gold">
              Team name :{" "}
              <span className="text-white">{data?.data?.teamName} </span>
            </h3>
            <h3 className="font-medium gold">
              Room Id :
              <span className="text-white"> {data?.data?.roomId} </span>
            </h3>
          </div>
          <div className="flex gap-2 justify-around max-sm:flex-col">
            {data?.data?.teamMember?.length > 0 && (
              <ConfirmationTable
                title="Team members"
                data={data?.data?.teamMember}
              />
            )}
            {data?.data?.waitingList?.length > 0 && (
              <ConfirmationTable
                title="Waiting room"
                data={data?.data?.waitingList}
                eventId={user?.data?.eventData?.eventId}
                refetch={refetch}
              />
            )}
          </div>
          <div className="d-lg-flex d-block ">
            <div className="col-lg-6 col-12 justify-center d-lg-flex d-none competInfoCol1">
              <img
                src={user?.data?.eventData?.image}
                alt="competInfo"
                className="img_wid"
              />
            </div>
            <div className="col-lg-6 col-12">
              <h1 className="fw-700 fs-20 textPrimary mb-0  mobile_size mt-2">
                Your Competition Information
              </h1>
              <div className="d-flex flex-col gap-1">
                <div className="competeInfoSec">
                  <h3 className="gold mb-0">Competition Name :</h3>
                  <p>{user?.data?.eventData?.title}</p>
                </div>
                <div className="competeInfoSec">
                  <h3 className="gold">Date: </h3>
                  <div className="d-flex gap-14">
                    <div className="col-10">
                      <p>Start Date : {startDate} </p>
                    </div>
                    {/* <div className="col-6">
                      <p>Start Time:</p>
                    </div> */}
                  </div>
                  <div className="d-flex gap-14">
                    <div className="col-8">
                      <p>End Date: {endDate}</p>
                    </div>
                    {/* <div className="col-6">
                      <p>Start Time:</p>
                    </div> */}
                  </div>
                </div>
                <div className="competeInfoSec">
                  <h3 className="gold">Location:</h3>
                  <p>Address: {user?.data?.eventData?.location}</p>
                </div>
                <div className="competeInfoSec">
                  <h3 className="gold">Ticket Type & Price:</h3>
                  <p className="gold fw-700">
                    {user?.data?.participation === "survivor" && (
                      <>
                        Survivor: ${user?.data?.eventData?.eventSurvivorAmount}
                      </>
                    )}
                    {user?.data?.participation === "both" && (
                      <>
                        Team & Survivor: $
                        {user?.data?.eventData?.eventTeamAmount +
                          user?.data?.eventData?.eventSurvivorAmount}
                      </>
                    )}
                    {user?.data?.participation === "team" && (
                      <>Team: ${user?.data?.eventData?.eventTeamAmount}</>
                    )}
                  </p>
                </div>
              </div>
              <button className="appDownloadBtn mt-4 fs-5 fw-700">
                Download The App{" "}
              </button>
            </div>
          </div>
          <div className="d-flex justify-center pt-10">
            {/* <div className="col-4 justify-end d-flex competInfoCol1">
              <img src={JoinCompetition} alt="joinCompetition" />
            </div> */}
            <div className="col-12 competInfoCol2">
              <h1 className="fw-700 fs-20 textPrimary text-center mb-0  mobile_size mt-lg-2 mt-0">
                Join Other Competitions
              </h1>
              {/* <div className="d-flex flex-col gap-3 pt-3">
                <div className="competeInfoSec">
                  <h3 className="text-white">Other Competition Name</h3>
                  <p>Other Competition Name</p>
                </div>
              </div> */}

              <EventCreationCard data={groupEvent} />

              {/* <div className="pt-20 d-flex  flex-col justify-center text-center otherCompetSec">
                <h1 className="ifNoOthertitle">
                  If No Other Competition Put in Bold <br />
                  “Other Competitions Comping Soon”
                </h1>
                <div className="flex flex-col gap-4 items-center confirmPaymentSec">
                  <img src={Logo} alt="Logo" width={200} />
                  <div className="d-flex gap-5">
                    <img src={AppStore} alt="AppStore" width={200} />
                    <img src={GooglePay} alt="GooglePay" width={200} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ConfirmationDetails;
