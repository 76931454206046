import React from "react";

import EventDetails from "../../components/molecules/eventDetails/EventDetails";

const EventDetailsPage = () => {
  return (
    <div className="pt-5 eventBG">
      <div className="container mrgin_top_hero mrgin_top_hero2">
        <EventDetails />
      </div>
    </div>
  );
};

export default EventDetailsPage;
