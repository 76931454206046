import React from 'react'
import logo from '../../assets/Logo.webp'
import './trusted.css'
const Trusted = () => {
  return (
    <div className='mrgin_new'>
      <div className='container-fluid  mt-lg-5 mt-0 pt-lg-5 pt-0 '>
        <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-12 justify-content-center text-center'>
                  <div className='box'>
                    {/* <p className='text-white fw-bold'>4.6/5 <img src={logo} alt="" className='logo_trusted'/></p> */}
                  </div>
                  <h2 className='container-8-2 mt-1 text-center mb-0 mob_fon2t'>What will you do in CompeteAE</h2>
                  {/* <p className='work-title mb-5'>Over 40,000 professionals have signed up for Pangea. Learn why we're ranked as one of the top platforms to find work on G2. */}

{/* </p> */}
            </div>
            <div className='col-lg-2'></div>
        </div>
      </div>
    </div>
  )
}

export default Trusted
