import React from "react"
import {useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {useNavigate, useSearchParams} from "react-router-dom"
// import {FaEye, FaEyeSlash} from "react-icons/fa"
import logo from "../../../assets/images/lgo.svg"
import {
  useConfirmPaymentMutation,
  usePaypalPaymentMutation,
} from "../../../Store/api/ApiSlice"
// import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js"
// import {useRef} from "react"
import {useEffect} from "react"
import {IoMdClose} from "react-icons/io"
const PaymentPopup = ({setShowPayment, showPayment, paymenttext}) => {
  const navigate = useNavigate()
  const [PaymentOpen, {isSuccess: popupSuccess}] = usePaypalPaymentMutation()
  const [ConfirmPayment, {isSuccess: ConfirmSuccess}] =
    useConfirmPaymentMutation()
  const [token, setToken] = useState(null)
  const [paypalRes, setPaypalRes] = useState([])
  const [amount, setAmount] = useState(0)
  const [params] = useSearchParams()
  useEffect(() => {
    let data = localStorage.getItem("paymentInfo")
    console.log("🚀 ~ useEffect ~ data:", data)
    data = JSON.parse(data)
    setAmount(data?.amount)
    setToken(data?.token)
  }, [])

  // console.log("params>>>", params.get("token"))

  const initialOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
  }
  const onSubmit = (e) => {
    if (amount > 0) {
      e.preventDefault()
      PaymentOpen({amount: amount})
        .then((res) => {
          console.log("🚀 ~ PaymentOpen ~ res:", res)
          localStorage.setItem(
            "paymentInfo",
            JSON.stringify({token: res?.data?.data?.token, amount: amount})
          )
          setToken(res?.data?.data?.token)
          setPaypalRes(res?.data?.data)
          window.location.href = res?.data?.data?.result?.links[1].href
        })
        .catch((error) => {
          console.log("🚀 ~ PaymentOpen ~ error:", error)
        })
    }
  }
  const confirmPaymentHandler = (e) => {
    e.preventDefault()
    const body = {
      token: token,
      paymentId: params.get("token"),
    }
    ConfirmPayment(body)
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res)
        localStorage.removeItem("paymentInfo")
        setShowPayment(false)
        navigate("/home")
      })
      .catch((error) => {
        console.log("🚀 ~ confirmPaymentHandler ~ error:", error)
      })
  }
  return (
    <Modal show={showPayment} centered className="loginContainer">
      <>
        <Modal.Header>
          <h4 className="fs-4 text-white fw-600">
            {paymenttext ? paymenttext : "Payment"}
          </h4>
          <IoMdClose
            color="#ffffff"
            fontSize={30}
            onClick={() => setShowPayment(false)}
            className="cursor-pointer"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-1 d-flex justify-center">
            <img
              src={logo}
              alt="Compete AE"
              className=""
              style={{width: "150px"}}
            />
          </div>
          <Form>
            <Form.Group className="mb-3" controlId="formUsername">
              <p className="popupLabels pb-1 fw-400 text-white">Enter Amount</p>
              <Form.Control
                type="text"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>
            <div className=" w-100 pt-3 d-flex flex-col items-center">
              <Button
                variant="primary"
                type="submit"
                className="w-60 fw-700 fs-6 popUpLogBtn"
                onClick={
                  token !== null && params.get("token")
                    ? confirmPaymentHandler
                    : onSubmit
                }
              >
                {token !== null && params.get("token")
                  ? "Confirm Payment"
                  : "Proceed"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <p className="text-white h6 fw-500">
            <span className="fs-6 pl-1 text-white fw--700 no-underline cursor-pointer">
              Paypal payment
            </span>
          </p>
        </Modal.Footer>
      </>
    </Modal>
  )
}

export default PaymentPopup
