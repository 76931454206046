import React from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Virtual } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import SimilarExpImg1 from "../../../assets/icons/similarExpImg1.svg";
import SimilarExpImg2 from "../../../assets/icons/similarExpImg2.svg";
import SimilarExpImg3 from "../../../assets/icons/similarExpImg3.svg";
import SimilarExpImg4 from "../../../assets/icons/similarExpImg4.svg";
import SimilarExpImg5 from "../../../assets/icons/similarExpImg5.svg";
import SimilarExpImg6 from "../../../assets/icons/similarExpImg6.svg";

import RatingStar from "../../../assets/icons/ratingStar.svg";

import "./sliders.css";

const similarExpData = [
  SimilarExpImg1,
  SimilarExpImg2,
  SimilarExpImg3,
  SimilarExpImg4,
  SimilarExpImg5,
  SimilarExpImg6,
];

const SimilarExperiences = () => {
  // Function to repeat the array
  const repeatArray = (array, times) => {
    let repeatedArray = [];
    for (let i = 0; i < times; i++) {
      repeatedArray = repeatedArray.concat(array);
    }
    return repeatedArray;
  };
  const repeatedSimilarExpData = repeatArray(similarExpData, 3);
  return (
    <div className="d-flex pt-1 similarExpTopSec">
      <Container className="container-9 relative">
        <h1 className="similarExpTitle textSecondary">
          Similar Experiences
        </h1>
        <Swiper
          modules={[Navigation, Pagination, Autoplay, Virtual]}
          spaceBetween={16}
          slidesPerView={4}
          breakpoints={{
            0: { slidesPerView: 1.2 },
            576: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
          }}
          navigation={{
            nextEl: ".swiper-button-next-custom",
            prevEl: ".swiper-button-prev-custom",
          }}
          // pagination={{ clickable: true, el: null }}
          // autoplay={{ delay: 3000 }}
          // virtual
          // loop
        >
          {repeatedSimilarExpData.map((slideContent, index) => (
            <SwiperSlide key={`slide-${index}`} virtualIndex={index}>
              <div className="slide py-3 rounded">
                <img
                  src={slideContent}
                  alt={`Similar Experience ${index + 1}`}
                  width="200px"
                  height="auto"
                  className="rounded-md similarExpSlideImg"
                />
                <div className="pt-3 d-flex flex-col gap-2">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={RatingStar} alt="ratingStar" />
                    <h1 className="fs-6">5.0 (67)</h1>
                  </div>
                  <p className="fs-6 fw-500">
                    Brooklyn Bridge & Dumbo Professional Photoshoot
                  </p>
                  <h1 className="fs-6  fw-70 0pt-1">
                    From Price:₹5,349 /
                    <span className="fw-400 fs-5">person</span>
                  </h1>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-next swiper-button-next-custom"></div>
        <div className="swiper-button-prev swiper-button-prev-custom"></div>
      </Container>
    </div>
  );
};

export default SimilarExperiences;
