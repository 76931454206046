import React from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Virtual } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import SimilarExpImg1 from "../../../assets/images/mobileSlider.png";
import SimilarExpImg2 from "../../../assets/icons/similarExpImg2.svg";
import SimilarExpImg3 from "../../../assets/icons/similarExpImg3.svg";
import SimilarExpImg4 from "../../../assets/icons/similarExpImg4.svg";
import SimilarExpImg5 from "../../../assets/icons/similarExpImg5.svg";
import SimilarExpImg6 from "../../../assets/icons/similarExpImg6.svg";

import "./sliders.css";

const similarExpData = [
  SimilarExpImg1,
  SimilarExpImg2,
  SimilarExpImg3,
  SimilarExpImg4,
  SimilarExpImg5,
  SimilarExpImg6,
];

const PhotoshootSlider = ({data}) => {
  return (
    <div className="mobileSliderCon relative p-2 pt-0">
      <h2 className="experienceHost textSecondary fs-5 fw-700 mb-2 text-center">{data?.data?.title}</h2>
      <Swiper
        modules={[Navigation, Pagination, Autoplay, Virtual]}
        spaceBetween={1}
        slidesPerView={4}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom",
        }}
        pagination={{ clickable: true, el: null }}
        autoplay={{ delay: 3000 }}
        virtual
        loop
      >
        {similarExpData.map((slideContent, index) => (
          <SwiperSlide key={`slide-${index}`} virtualIndex={index}>
            <div className="">
              <img
                src={slideContent}
                alt={`Similar Experience ${index + 1}`}
                width="198.29px"
                height="auto"
                className="rounded-lg similarExpSlideImg"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-button-next swiper-button-next-custom"></div>
      <div className="swiper-button-prev swiper-button-prev-custom"></div>
    </div>
  );
};

export default PhotoshootSlider;
