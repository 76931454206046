import React from "react";
import ConfirmationDetails from "../../components/molecules/confirmationDetails/ConfirmationDetails";

const ConfirmationPage = () => {
  return (
    <div className="pt-5 eventBG">
      <div className="bg_clr mrgin_top_hero">
        <ConfirmationDetails />
      </div>
    </div>
  );
};

export default ConfirmationPage;
