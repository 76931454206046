import React from "react";
import { Container } from "react-bootstrap";

import HostProfile from "../../../assets/icons/hostProfile.svg";
import ReviewStar from "../../../assets/icons/reviewStar.svg";
import VerifyIcon from "../../../assets/icons/verifyIcon.svg";

const MeetYourHost = ({ data }) => {
  const { profileImage, fullName } = data?.creatorData || {};
  const reviewsCount = 331; // Placeholder; replace with actual data if available
  const isVerified = true; // Placeholder; replace with actual data if available

  return (
    <>
    </>
    // <div className="d-flex flex-col meetHostTopSec">
    //   <Container className="d-flex md:gap-4 gap-1">
    //     <img
    //       src={profileImage || HostProfile}
    //       alt="Host Profile"
    //       className="meet-host-image"
    //     />
    //     <div className="col-md-6 col-9 d-flex flex-col gap-4">
    //       <div>
    //         <h1 className="fw-700 fs-29 yourHostTitle textSecondary">
    //           Meet your host - {fullName || "Unknown"}
    //         </h1>
    //         <div className="d-flex flex-wrap md:gap-8 gap-2 pt-1">
    //           <div className="d-flex items-center gap-2">
    //             <img className="h-8" src={ReviewStar} alt="Review Star" />
    //             <p className="textSecondary fw-500 whitespace-nowrap">
    //               {reviewsCount} Reviews
    //             </p>
    //           </div>
    //           <div className="d-flex items-center gap-2">
    //             <img className="h-8" src={VerifyIcon} alt="Verify Icon" />
    //             <p className="textSecondary whitespace-nowrap fw-500">
    //               {isVerified ? "Identity Verified" : "Not Verified"}
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <p className="fw-400 meetHostDesc">
    //         I have been doing photography for 9 years now. I’m a musical theatre
    //         performer as well. I am also a born and raised New Yorker. Rare!
    //         Haha. I know all the best spots, best poses, and best angles to give
    //         you a treat of a experience. One of a kind over here.
    //       </p>
    //     </div>
    //   </Container>
    //   <div className="contactHostBtnSec">
    //     <button className="contactHostBtn mt-4 fw-700">Contact Host</button>
    //   </div>
    // </div>
  );
};

export default MeetYourHost;
