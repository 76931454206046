import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./Expend.css";
import work1 from '../../assets/work1.png'
import workhead2 from '../../assets/workhead2.jpeg'
import work3 from '../../assets/work3.png'
import work4 from '../../assets/work4.png'
import CountUp from 'react-countup';
// import { CountUp } from 'use-count-up'
import ScrollTrigger from 'react-scroll-trigger'
const Expend = () => {
  const [counter, setCounter] = useState(false);

 
  return (
    <div
      className="container mt-lg-5 mt-0 pt-lg-5 pt-0"
      data-aos="zoom-in"
      data-aos-duration="5000"
    >
      <div className="row">
        <div className="work-title justify-content-center text-center">
        <ScrollTrigger onEnter={()=>{ setCounter(true)}} onExit={()=>{ setCounter(false)}}>
         
          <h2 className="mt-3 container-8-2 mob_font text-center">
              {
                counter && <CountUp start={1} end={2400} duration={2.78} delay={1} />
              }
                  + Competitions <br /> 4 ways to  Compete</h2>
              </ScrollTrigger>
          {/* <button className="cta-btn mt-2 mb-3">
                Download App Now
              </button> */}
          <div className="d-flex flex-wrap-responsive gap-3 mt-3">
            <div className="col-lg-3 col-12">
              <div className="card bg_head" data-aos="zoom-in">
                {/* <img
                  src={work1}
                  className="gl-card-icon rounded"
                /> */}
                <h3 className="clr mt-3 mb-4">Self Challenge</h3>
                <p className="text-white">
                Create a Goal and challenge yourself, announce it to friends and see how they vote. Or Create a competition for friend and help them Improve.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="card bg_team" data-aos="zoom-in" data-aos-duration="5000">
                {/* <img
                  src={work4}
                  className="gl-card-icon rounded"
                /> */}
                <h3 className="clr mt-3 mb-4">Teams</h3>
                <p className="text-white">
                Hitting Goals with A Partner is always more FUN. Create a Competition for all of your friends and breakout into teams, strategize and come up with the game plan to beat the other team. 
                </p>
              </div>
            </div>
            <div className="col-lg-3  col-12">
              <div className="card" data-aos="zoom-in" data-aos-duration="5000">
                {/* <img
                  src={workhead2}
                  className="gl-card-icon rounded"
                /> */}
                <h3 className="clr mt-3 mb-4">Head To Head</h3>
                <p className="text-white">
                Create Competition Challenging someone else and prove once and for all who really is the best, There's no losers when you both improve but there is only ONE Winner.
                </p>
              </div>
            </div>
            <div className="col-lg-3  col-12">
              <div className="card" data-aos="zoom-in" data-aos-duration="5000">
                {/* <img
                  src={work3}
                  className="gl-card-icon rounded"
                /> */}
                <h3 className="clr mt-3 mb-4">Survivor</h3>
                <p className="text-white">
                Invite all of your friends and you each Compete against each other, only one Winner, many improved individuals.
                </p>
              </div>
            </div>
          </div>{" "}
        
        </div>
       
      </div>
    </div>
  );
};

export default Expend;
