import React from 'react';
import Img from '../Images/favicon2.png';
import { Link, useLocation } from 'react-router-dom';

const HeaderNav = ({ handleClick }) => {
  const location = useLocation();
  return (
    <div className="flex justify-between px-8 md:px-24 py-10">
      {/* <div className="max-w-[6rem] py-2 md:py-0">
        <Link to="/">
          <img src={Img} alt="Logo" className="w-full h-auto" />
        </Link>
      </div> */}
      <div className="flex sm:flex-none ml-4">
        {location.pathname !== '/t&c' && location.pathname !== '/policy' && (
          <button
            onClick={handleClick}
            className="text-black font-bold bg-gradient-to-r from-[#DDD036] via-[#FAD550] to-[#E1FC85] px-8 py-2 sm:px-8 sm:py-4 rounded-md ml-4 text-xs md:text-base"
          >
            SIGNUP FOR UPDATES
          </button>
        )}
        {/* <button className="text-black font-bold bg-gradient-to-r from-[#DDD036] via-[#FAD550] to-[#E1FC85] px-8 py-2 sm:px-8 sm:py-4 rounded-md ml-4">
          GET APP
        </button> */}
      </div>
    </div>
  );
};

export default HeaderNav;
