import React, { useEffect, useState } from "react";
import Overlay from "../../overlay/Overlay";

const MainEventCard = ({
  latestEvent,
  latestEventDate,
  eventDetailsFunc,
  formattedStartDate,
}) => {

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const description = latestEvent[0]?.description;
  const displayedText = isDesktop
    ? description.slice(0, 100)
    : description.slice(0, 25);

  return (
    <div
      className="nextCompetSection position-relative gap-16"
      style={{ backgroundImage: `url(${latestEvent[0]?.images[0]})` }}
      // style={{ backgroundImage: `url(${imageUrl})` }}
      onClick={eventDetailsFunc}
    >
      <Overlay zIndex={10} />
      <div className="flex flex-col nextExcitingSec z-10 gap-16">
        <h1 className="fastToComplete text-center mb-lg-0 mb-3 text-white">
          {latestEvent[0]?.title}
        </h1>
        {/* <p className="nextCompetText lightGreen text-center fw-600">
          {latestEventDate}
        </p> */}
        <div className="d-flex flex-col gap-3 justify-content-center align-items-center">
          {/* <h1 className="fastToComplete text-[#ffee56]">
            {latestEvent[0]?.title}
          </h1> */}
          {/* Desc: {latestEvent[0]?.description.slice(0, 50)}...
           */}
          <p className="nextDescText text-white text-center d-lg-block d-none lightGreen w-100 full-width-text">
            Desc: {displayedText}...
          </p>
          {/* <p className="nextDescText lightGreen text-lg-center text-start">
             Desc: {displayedText}...
          </p> */}
        </div>
      </div>
      <div className="flex flex-lg-row flex-col flex-wrap gap-0 justify-content-between align-items-center z-10">
        <p className="ticketFrom fw-600">
          <span className="fw-normal">From</span> - $
          {latestEvent[0]?.minAmount ?? 0} on {formattedStartDate}
        </p>
        {/* <br /> */}
        <p className="nextCompetText fw-600 mb-1">
          <span className="fw-normal">Includes:</span> Ticket To Park, Winners
          ...
        </p>
        <button onClick={eventDetailsFunc} className="joinInBtn">
          Join in
        </button>
      </div>
    </div>
  );
};

export default MainEventCard;
