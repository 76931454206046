import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

import Planet from "../../assets/Planet.webp";
import iphone from "../../assets/images/iphone.png";
import iphone2 from "../../assets/images/iphone2.png";


import "./Home.css";
import TakeControl from "../takecontrol/TakeControl";
import Mission from "../mission/Mission";
import Expend from "../expend/Expend";
import Cator from "../cator/Cator";
import Trusted from "../trusted/Trusted";
import TrustedCards from "../TrustedCards/TrustedCards";
import FindWork from "../findwork/FindWork";
import Work from "../work/Work";
import FrequentlyAccordion from "../Faq";
import backgrounds from "../../assets/backgrounds/Rectangle47.png";
import background1 from "../../assets/backgrounds/Rectangle48.png";
import background2 from "../../assets/backgrounds/Rectangle45.png";

const Home = () => {
  return (
    <div className="pt-5">
      <div className="bg_clr mrgin_top_hero">
        <img className="background-effect-1" src={backgrounds} alt="background" />
        <img className="background-effect" src={background2} alt="background" />
        <Container>
          <Row className="align-items-center ">
            <Col
              md={7}
              className="mt-2 col"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <span className="gl-hero-lead-in mt-2">COMPETEAE WHERE YOU</span>
              <h1 className="container-8">
                Bet On Yourself <br className="d-lg-block d-none" /> and Earn it
              </h1>
              <p className="gl-hero-body ">
                Create A Competition Now, challenging yourself or a friend
              </p>

              <div className="mt-3 mb-5 d-lg-block d-none underlines">
                <a
                  href="https://apps.apple.com/in/app/competeae/id6444800738"
                  target="blank"
                >
                  <button className="btn btn_login px-4">Notify Me</button>
                </a>
              </div>
            </Col>
            <Col md={5} className="text-end bg_mobile">
              <img
                className="background-effect-mobile d-lg-block"
                src={background1}
                alt="background"
              />
              <img src={iphone2} alt="" className="img_res " />
            </Col>
          </Row>

          {/* <FindWork /> */}
        </Container>

        <TakeControl />
        {/* <Mission /> */}
        <Work />
      </div>
      <Expend />
      <div className="bg_grad mt-5">
        <div className="bg_container">
          <div className="bg_drop">
            <Cator />
          </div>
        </div>
      </div>
            <Trusted />
    <div className="bg_cards">
      <TrustedCards />

    </div>
      <FrequentlyAccordion />
    </div>
  );
};

export default Home;
