import React from "react";
import "./cards.css";
import testi from "../../assets/images/mike.png";
import testi1 from "../../assets/images/mari.png";
import testi2 from "../../assets/images/ronald.png";
import testi3 from "../../assets/images/soham.png";
import testi4 from "../../assets/images/arlene.png";
import testi5 from "../../assets/images/courtney.png";

const TrustedCards = () => {
  return (
    <div>
      <div className="container mt-lg-5 mt-0  pt-lg-5 pt-0">
        <div className="row align-items-center p-4">
          <div className="col-lg-4 col-6 mb-3 px-2">
            <div
              className="card_trust2 ring-1 ring-[#b337ac]"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <div className="d-block align-items-center gap-3">
                <img src={testi} alt="" className="testi" />
                <div className="d-block">
                  <h4 className="mb-1">Mike M</h4>
                  {/* <p className='card_text'>Website Builder/CMS Specialist</p> */}
                </div>
              </div>
              <p className="work-title">
                Challenge myself to have passive income by a certain date.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-6 px-2 mb-3">
            <div
              className="card_trust2 ring-1 ring-[#b337ac]"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <div className="d-block align-items-center gap-3">
                <img src={testi1} alt="" className="testi" />
                <div className="d-block">
                  <h4 className="mb-1">Mike M</h4>
                  {/* <p className='card_text'>Website Builder/CMS Specialist</p> */}
                </div>
              </div>
              <p className="work-title">Reduce my body fat</p>
            </div>
          </div>

          <div className="col-lg-4 col-6 px-2 mb-3">
            <div
              className="card_trust2 ring-1 ring-[#b337ac]"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <div className="d-block  align-items-center gap-3">
                <img src={testi2} alt="" className="testi" />
                <div className="d-block">
                  <h4 className="mb-1">Marjorie</h4>
                  {/* <p className='card_text'>Website Builder/CMS Specialist</p> */}
                </div>
              </div>
              <p className="work-title">Lose weight</p>
            </div>
          </div>
          <div className="col-lg-4 col-6 px-2 mb-3">
            <div
              className="card_trust2 ring-1 ring-[#b337ac]"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <div className="d-block align-items-center gap-3">
                <img src={testi3} alt="" className="testi" />
                <div className="d-block">
                  <h4 className="mb-1">Jose O</h4>
                  {/* <p className='card_text'>Website Builder/CMS Specialist</p> */}
                </div>
              </div>
              <p className="work-title">
                Challenge a friend to stop Drinking for a certain period of
                time.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-6 px-2 mb-3">
            <div
              className="card_trust2 ring-1 ring-[#b337ac]"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <div className="d-block align-items-center gap-3">
                <img src={testi4} alt="" className="testi" />
                <div className="d-block">
                  <h4 className="mb-1">Antonette E</h4>
                  {/* <p className='card_text'>Website Builder/CMS Specialist</p> */}
                </div>
              </div>
              <p className="work-title">Lose weight before my Girls Trip.</p>
            </div>
          </div>
          <div className="col-lg-4 col-6 px-2 mb-3">
            <div
              className="card_trust2 ring-1 ring-[#b337ac]"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <div className="d-block align-items-center gap-3">
                <img src={testi5} alt="" className="testi" />
                <div className="d-block">
                  <h4 className="mb-1">Anthony C Jr</h4>
                  {/* <p className='card_text'>Website Builder/CMS Specialist</p> */}
                </div>
              </div>
              <p className="work-title">
                Challenge my brother to a arm wrestling contest.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedCards;
