import React from "react";
import { Container } from "react-bootstrap";

import TicketIcon from "../../../assets/icons/ticket.svg";
import EquipmentIcon from "../../../assets/icons/equipment.svg";
import AppIcon from "../../../assets/icons/appIcon.svg";

import bg from "../../../assets/backgrounds/glows.png";

import WhatsIncludedCard from "./component/WhatsIncludedCard";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Virtual } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const includedData = [
  {
    icon: TicketIcon,
    title: "Tickets",
    describtion:
      "You’ll receive a preview link of all photos in 24hours. You can select 1 co...",
  },
  {
    icon: EquipmentIcon,
    title: "EquipmentIcon",
    describtion: "Lenses: 24 1.4 G Master, 35 1.4, 50 1.8, 85...",
  },
  {
    icon: TicketIcon,
    title: "TicketIcon",
    describtion:
      "You’ll receive a preview link of all photos in 24hours. You can select 1 co...",
  },
  {
    icon: TicketIcon,
    title: "Tickets",
    describtion:
      "You’ll receive a preview link of all photos in 24hours. You can select 1 co...",
  },
];

const equipmentData = [
  {
    icon: AppIcon,
    title: "Tickets",
    describtion:
      "You’ll receive a preview link of all photos in 24hours. You can select 1 co...",
  },
  {
    icon: EquipmentIcon,
    title: "EquipmentIcon",
    describtion: "Lenses: 24 1.4 G Master, 35 1.4, 50 1.8, 85...",
  },
  {
    icon: TicketIcon,
    title: "TicketIcon",
    describtion:
      "You’ll receive a preview link of all photos in 24hours. You can select 1 co...",
  },
  {
    icon: TicketIcon,
    title: "Tickets",
    describtion:
      "You’ll receive a preview link of all photos in 24hours. You can select 1 co...",
  },
];

const WhatIncluded = ({ data }) => {
  return (
    <div className="d-flex">
      <Container className="d-block whatsIncludeSec p-0">
        <div className="col-12 whatsIncludeCol1">
          <h1 className="fw-700 fs-33 textSecondary text-center mobile_size">
            What's included?
          </h1>
          <div className="relative includedCardSec d-flex  flex-wrap justify-center w-100">
            <img className="absolute" src={bg} alt="" />
            <Swiper
              modules={[Navigation, Pagination, Autoplay, Virtual]}
              spaceBetween={16}
              slidesPerView={
                data?.itemIncluded?.length < 4 ? data?.itemIncluded?.length : 4
              }
              breakpoints={{
                0: {
                  slidesPerView:
                    data?.itemIncluded?.length < 2
                      ? data?.itemIncluded?.length
                      : 2.1,
                },
                576: {
                  slidesPerView:
                    data?.itemIncluded?.length < 2
                      ? data?.itemIncluded?.length
                      : 2.1,
                },
                992: {
                  slidesPerView:
                    data?.itemIncluded?.length < 3
                      ? data?.itemIncluded?.length
                      : 3,
                },
                1200: {
                  slidesPerView:
                    data?.itemIncluded?.length < 4
                      ? data?.itemIncluded?.length
                      : 4,
                },
              }}
              autoplay={{ delay: 3000 }}
            >
              {data?.itemIncluded?.map((includedItems, index) => (
                <SwiperSlide key={`slide-${index}`} virtualIndex={index}>
                  <div className="includedCard includedCard2 glassy p-lg-3 p-1">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <img
                        src={includedItems?.icon}
                        alt="cardIcon"
                        width={35}
                        height={35}
                        className="rounded-md"
                        style={{ height: "44px", width: "51px" }}
                      />
                      <h3 className="fs-5 fw-700 includeCardTitle">
                        {includedItems?.title}
                      </h3>
                    </div>
                    <p className="fw-400 includeCardPara">
                      {includedItems?.description}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* <div className="relative includedCardSec d-lg-flex d-none  flex-wrap justify-center gap-3 w-100">
          {data?.itemIncluded?.map((includedItems, index) => (
              <WhatsIncludedCard key={index} includedItems={includedItems} />
            ))}
          </div> */}
        </div>

        <div className="verticalLine w-100" />
        <div className="col-12 whatsIncludeCol2">
          {/* <h1 className="text-center fs-3 whatIncludeHeading">What's Needed?</h1>
           */}
          <h1 className="fw-700 fs-33 mt-3 textSecondary text-center mobile_size">
            What's Needed?
          </h1>

          <div className="relative includedCardSec  d-flex  flex-wrap justify-center w-100">
            <img className="absolute" src={bg} alt="" />
            <Swiper
              modules={[Navigation, Pagination, Autoplay, Virtual]}
              spaceBetween={16}
              slidesPerView={
                data?.itemNeeded?.length < 4 ? data?.itemNeeded?.length : 4
              }
              breakpoints={{
                0: {
                  slidesPerView:
                    data?.itemNeeded?.length < 2
                      ? data?.itemNeeded?.length
                      : 2.1,
                },
                576: {
                  slidesPerView:
                    data?.itemNeeded?.length < 2
                      ? data?.itemNeeded?.length
                      : 2.1,
                },
                992: {
                  slidesPerView:
                    data?.itemNeeded?.length < 3 ? data?.itemNeeded?.length : 3,
                },
                1200: {
                  slidesPerView:
                    data?.itemNeeded?.length < 4 ? data?.itemNeeded?.length : 4,
                },
              }}
              autoplay={{ delay: 3000 }}
            >
              {data?.itemNeeded?.map((equipmentItems, index) => (
                <SwiperSlide key={`slide-${index}`} virtualIndex={index}>
                  <div className="includedCard includedCard2 glassy p-lg-3 p-1">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <img
                        src={equipmentItems?.icon}
                        alt="cardIcon"
                        width={35}
                        height={35}
                        className="rounded-md"
                        style={{ height: "44px", width: "51px" }}
                      />
                      <h3 className="fs-5 fw-700 includeCardTitle">
                        {equipmentItems?.title}
                      </h3>
                    </div>
                    <p className="fw-400 includeCardPara">
                      {equipmentItems?.description}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* <div className="relative includedCardSec d-flex flex-wrap justify-center gap-3 w-100">
            <img className="absolute" src={bg} alt="" />
            {data?.itemNeeded?.map((equipmentItems, index) => (
              <WhatsIncludedCard key={index} includedItems={equipmentItems} />
            ))}
          </div> */}

          {/* <div className="relative includedCardSec d-lg-flex d-none  flex-wrap justify-center gap-3 w-100">
{data?.itemNeeded?.map((equipmentItems, index) => (
              <WhatsIncludedCard key={index} includedItems={equipmentItems} />
            ))}
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default WhatIncluded;
