import HeaderNav from './../components/headerNav';

function Policy() {
  return (
    <div className="w-full h-auto bg-[#080402] text-white">
      {/* //Notes: Header */}
      <HeaderNav />

      {/* //Notes: Policy text */}

      <div className=" px-8 md:px-24">
        <div className="leading-8">
          <h1 className="text-white text-4xl md:text-7xl bold ">Privacy Policy</h1>
          <p className="mt-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          </p>
          <p className="text-[#EDCB76] mt-4">Last updated on : September 21st , 2023</p>
          <hr className="border-gray-900 mt-10" />
        </div>
        <div className="mt-4">
          <p>
            This privacy notice for Compete AE Inc. ( " Company ," "we," "us," or "our" ), describes how and why we
            might collect, store, use, and/or share ( "process" ) your information when you use our services (
            "Services" ), such as when you:
          </p>

          <ul className="list-disc ml-8">
            <li>
              <p className="line_space">
                Visit our website at{' '}
                <span className="text-[#EDCB76]">
                  {' '}
                  <a href="https://competeae.com/">CompeteAE</a>
                </span>
                , or any website of ours that links to this privacy notice.
              </p>
            </li>
            <li>
              <p className="line_space">
                Download and use our mobile application ( Compete AE) , or any other application of ours that links to
                this privacy notice
              </p>
            </li>
            <li>
              <p className="line_space">
                Engage with us in other related ways, including any sales, marketing, or events
              </p>
            </li>
          </ul>

          {/*//Notes: Q | C section  */}

          <p className="my-8">Question or concerns?:</p>
          <p className="mb-8">
            Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree
            with our policies and practices, please do not use our Services. If you still have any questions or
            concerns, please contact us at
            <span className="text-[#EDCB76]">
              <a href="Jortiz@competecae.com"> Jortiz@competecae.com </a>
            </span>
            .
          </p>
          {/*//Notes: SUMMERY OF KEY POINT */}
          <p className="mb-8 text-[#EDCB76] font-bold ">SUMMERY OF KEY POINT</p>
          <p className="mb-8 font-semibold">
            This summary provides key points from our privacy notice, but you can find out more details about any of
            these topics by clicking the link following each key point or by using our table of contents below to find
            the section you are looking for. You can also{' '}
            <span className="text-[#EDCB76] underline">
              <a href="https://privacy.competeae.com/#toc"> click here </a>
            </span>
            to go directly to our table of contents.
          </p>

          <p className="leading-loose font-semibold">What personal information do we process?</p>
          <p className="mb-8">
            When you visit, use, or navigate our Services, we may process personal information depending on how you
            interact with Compete AE Inc. and the Services, the choices you make, and the products and features you use.
            <span className="text-[#EDCB76] underline">
              {' '}
              <a href="https://privacy.competeae.com/#personalinfo">click here</a>
            </span>{' '}
            to learn more.
          </p>
          {/* 2 */}
          <p className="leading-loose font-semibold">Do we process any sensitive personal information?</p>
          <p className="mb-8">
            We may process sensitive personal information when necessary with your consent or as otherwise permitted by
            applicable law.{' '}
            <span className="text-[#EDCB76]">
              <a href="https://privacy.competeae.com/#sensitiveinfo"> click here</a>
            </span>{' '}
            to learn more.
          </p>
          {/* 3 */}
          <p className="leading-loose font-semibold">Do we receive any information from third parties?</p>
          <p className="mb-8">
            We may receive information from public databases, marketing partners, social media platforms, and other
            outside sources.{' '}
            <span className="text-[#EDCB76]">
              <a href="https://privacy.competeae.com/#othersources">click here</a>
            </span>{' '}
            to learn more.
          </p>
          {/* 4 */}

          <p className="leading-loose font-semibold">How do we process your information?</p>
          <p className="mb-8">
            We process your information to provide, improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we have a valid legal reason to do so.
            <span className="text-[#EDCB76]">
              <a href="https://privacy.competeae.com/#infouse"> click here</a>
            </span>{' '}
            to learn more.
          </p>
          {/* 5 */}

          <p className="leading-loose font-semibold">
            In what situations and with which parties do we share personal information?
          </p>
          <p className="mb-8">
            We may share information in specific situations and with specific third parties.{' '}
            <span className="text-[#EDCB76]">
              <a href="https://privacy.competeae.com/#whoshare"> click here</a>
            </span>{' '}
            to learn more.
          </p>
          {/* 6 */}
          <p className="leading-loose font-semibold">How do we keep your information safe?</p>
          <p className="mb-8">
            We have organizational and technical processes and procedures in place to protect your personal information.
            However, no electronic transmission over the internet or information storage technology can be guaranteed to
            be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
            parties will not be able to defeat our security and improperly collect, access, steal, or modify your
            information.{' '}
            <span className="text-[#EDCB76]">
              <a href="https://privacy.competeae.com/#infosafe">click here</a>
            </span>{' '}
            to learn more.
          </p>
          {/* 7 */}
          <p className="leading-loose font-semibold">What are your rights?</p>
          <p className="mb-8">
            The easiest way to exercise your rights is by filling out our data subject request form available{' '}
            <span className="text-[#EDCB76] underline">
              <a href="https://app.termly.io/notify/415f241e-5358-4d19-8de3-9b36c3d568fe">here</a>
            </span>{' '}
            , or by contacting us. We will consider and act upon any request in accordance with applicable data
            protection laws.
          </p>

          <p className="mb-8">
            Want to learn more about what Compete AE Inc. does with any information we collect?{' '}
            <span className="text-[#EDCB76]">
              <a href="https://privacy.competeae.com/#toc">click here</a>
            </span>{' '}
            to review the notice in full.
          </p>

          {/*//Notes: What info we collect */}
          <p className="mb-8 text-[#EDCB76] font-bold ">WHAT INFORMATION DO WE COLLECT?</p>
          <p className="mb-8 font-semibold"> Personal information you disclose to us </p>
          <p className="mb-8">In Short: We collect personal information that you provide to us.</p>
          <p className="mb-8">
            We collect personal information that you voluntarily provide to us when you register on the Services,
            express an interest in obtaining information about us or our products and Services, when you participate in
            activities on the Services, or otherwise when you contact us.
          </p>
          <p className="mb-8 font-bold ">Personal Information Provided by You.:</p>
          <p className="mb-8">
            The personal information that we collect depends on the context of your interactions with us and the
            Services, the choices you make, and the products and features you use. The personal information we collect
            may include the following:’
          </p>

          <ul className="list-disc ml-8">
            <li>
              <p className="mb-8">Names</p>
            </li>
            <li>
              <p className="mb-8">Phone numbers</p>
            </li>
            <li>
              <p className="mb-8">Email addresses</p>
            </li>
            <li>
              <p className="mb-8">Usernames</p>
            </li>
            <li>
              <p className="mb-8">passwords</p>
            </li>
            <li>
              <p className="mb-8">debit/credit card numbers</p>
            </li>
            <li>
              <p className="mb-8">debit/credit card details</p>
            </li>
            <li>
              <p className="mb-8">fitbit</p>
            </li>
            <li>
              <p className="mb-8">garmin</p>
            </li>
            <li>
              <p className="mb-8">withings</p>
            </li>
            <li>
              <p className="mb-8">plaid</p>
            </li>
            <li>
              <p className="mb-8">other apis</p>
            </li>
          </ul>

          {/* //Notes: senstive information */}
          <p className="mb-8 font-bold ">Sensitive information.:</p>

          <p className="mb-8">
            When necessary, with your consent or as otherwise permitted by applicable law, we process the following
            categories of sensitive information:
          </p>

          <ul className="list-disc ml-8">
            <li>
              <p className="mb-8">Health data</p>
            </li>
            <li>
              <p className="mb-8">financial data</p>
            </li>
          </ul>

          {/* //Notes: payment data */}
          <p className="mb-8 font-bold ">Payment Data:</p>

          <p className="mb-8">
            We may collect data necessary to process your payment if you make purchases, such as your payment instrument
            number (such as a credit card number), and the security code associated with your payment instrument. All
            payment data is stored by Stripe.com . You may find their privacy notice link(s) here:
            <span className="underline">https://stripe.com/privacy</span> .
          </p>

          {/* //Notes: application data */}
          <p className="mb-8 font-bold ">Application Data:</p>

          <p className="mb-8">
            If you use our application(s), we also may collect the following information if you choose to provide us
            with access or permission:
          </p>
          <ul className="list-disc ml-8">
            <li>
              <p className="">
                Push Notifications. We may request to send you push notifications regarding your account or certain
                features of the application(s). If you wish to opt out from receiving these types of communications, you
                may turn them off in your device's settings.
              </p>
            </li>
          </ul>

          <p className="my-8">
            This information is primarily needed to maintain the security and operation of our application(s), for
            troubleshooting, and for our internal analytics and reporting purposes.
          </p>

          <p className="mb-8">
            All personal information that you provide to us must be true, complete, and accurate, and you must notify us
            of any changes to such personal information.
          </p>

          {/* //Notes: information collect by other source */}
          <p className="mb-8 font-bold ">Information collected from other sources</p>

          <p className="mb-8">
            In Short: We may collect limited data from public databases, marketing partners, and other outside sources.
          </p>
          <p>
            In order to enhance our ability to provide relevant marketing, offers, and services to you and update our
            records, we may obtain information about you from other sources, such as public databases, joint marketing
            partners, affiliate programs, data providers, and from other third parties. This information includes
            mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet
            Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of
            targeted advertising and event promotion.
          </p>
          {/*//Notes: how to do process your data */}
          <p className="my-8 text-[#EDCB76] font-bold">HOW DO WE PROCESS YOUR INFORMATION</p>

          <p className="mb-8">
            In Short: We process your information to provide, improve, and administer our Services, communicate with
            you, for security and fraud prevention, and to comply with law. We may also process your information for
            other purposes with your consent.
          </p>

          <p>
            We process your personal information for a variety of reasons, depending on how you interact with our
            Services, including:
          </p>

          <ul className="list-disc my-8 ml-8">
            <li>
              <p className="mb-8">
                To facilitate account creation and authentication and otherwise manage user accounts. We may process
                your information so you can create and log in to your account, as well as keep your account in working
                order.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To deliver and facilitate delivery of services to the user. We may process your information to provide
                you with the requested service.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To respond to user inquiries/offer support to users. We may process your information to respond to your
                inquiries and solve any potential issues you might have with the requested service.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To send administrative information to you. We may process your information to send you details about our
                products and services, changes to our terms and policies, and other similar information.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To deliver targeted advertising to you. We may process your information to develop and display
                personalized content and advertising tailored to your interests, location, and more.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To post testimonials. We post testimonials on our Services that may contain personal information.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To protect our Services. We may process your information as part of our efforts to keep our Services
                safe and secure, including fraud monitoring and prevention.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To administer prize draws and competitions. We may process your information to administer prize draws
                and competitions.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To determine the effectiveness of our marketing and promotional campaigns. We may process your
                information to better understand how to provide marketing and promotional campaigns that are most
                relevant to you.
              </p>
            </li>
            <li>
              {' '}
              <p className="mb-8">
                To comply with our legal obligations. We may process your information to comply with our legal
                obligations, respond to legal requests, and exercise, establish, or defend our legal rights.
              </p>
            </li>
          </ul>

          {/*//Notes: how do we process your info */}
          <p className="my-8 text-[#EDCB76] font-bold">HOW DO WE PROCESS YOUR INFORMATION?</p>

          <p className="mb-8">
            In Short: We may share information in specific situations described in this section and/or with the
            following third parties.
          </p>

          <p className="mb-8">We may need to share your personal information in the following situations:</p>

          <ul className="list-disc ml-8">
            <li>
              <p className="mb-8">
                <span className="font-bold">Business Transfer.</span> We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business to another company
              </p>
            </li>
          </ul>

          {/* //Notes: keep info */}
          <p className="my-8 text-[#EDCB76] font-bold">HOW LONG DO WE KEEP YOUR INFORMATION?</p>

          <p className="mb-8">
            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
            notice unless otherwise required by law.
          </p>

          <p className="mb-8">
            We will only keep your personal information for as long as it is necessary for the purposes set out in this
            privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting,
            or other legal requirements). No purpose in this notice will require us keeping your personal information
            for longer than twelve (12) months past the start of the idle period of the user's account.
          </p>

          <p className="mb-8">
            When we have no ongoing legitimate business need to process your personal information, we will either delete
            or anonymize such information, or, if this is not possible (for example, because your personal information
            has been stored in backup archives), then we will securely store your personal information and isolate it
            from any further processing until deletion is possible.
          </p>

          {/*//Notes: keep info safe */}
          <p className="my-8 text-[#EDCB76] font-bold">HOW DO WE KEEP YOUR INFORMATION SAFE?</p>

          <p className="mb-8">
            In Short: We aim to protect your personal information through a system of organizational and technical
            security measures.
          </p>

          <p className="mb-8">
            We have implemented appropriate and reasonable technical and organizational security measures designed to
            protect the security of any personal information we process. However, despite our safeguards and efforts to
            secure your information, no electronic transmission over the Internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cyber criminals, or other
            unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect your personal information, transmission of
            personal information to and from our Services is at your own risk. You should only access the Services
            within a secure environment.
          </p>

          {/*//Notes: what are your privacy rights */}
          <p className="my-8 text-[#EDCB76] font-bold">WHAT ARE YOUR PRIVACY RIGHTS?</p>

          <p className="mb-8">In Short: You may review, change, or terminate your account at any time.</p>

          <p className="mb-8">
            If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
            you also have the right to complain to your local data protection supervisory authority. You can find their
            contact details here:
            <span className="text-[#EDCB76] underline">
              <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">click here</a>.
            </span>
          </p>

          <p className="mb-8">
            If you are located in Switzerland, the contact details for the data protection authorities are available
            here:
            <span className="text-[#EDCB76] underline">
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">click here</a>
            </span>
            .
          </p>

          <p className="mb-8">
            Withdrawing your consent: If we are relying on your consent to process your personal information, which may
            be express and/or implied consent depending on the applicable law, you have the right to withdraw your
            consent at any time. You can withdraw your consent at any time by contacting us by using the contact details
            provided in the section <span className="underline">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</span>{' '}
            below.
          </p>

          <p className="mb-8">
            However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
            when applicable law allows, will it affect the processing of your personal information conducted in reliance
            on lawful processing grounds other than consent.
          </p>

          {/*//Notes: account information */}
          <p className="mb-8 font-semibold">Account information</p>
          <p className="mb-8">
            If you would at any time like to review or change the information in your account or terminate your account,
            you can:
          </p>

          <ul className="list-disc ml-8">
            <li>
              <p className="mb-8">Log in to your account settings and update your user account.</p>
            </li>
          </ul>

          <p className="mb-8">
            Upon your request to terminate your account, we will deactivate or delete your account and information from
            our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
            problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>

          <p className="mb-8 font-semibold">
            If you have questions or comments about your privacy rights, you may email us at
            <span className="text-[#EDCB76]"> Privacy@competecae.com</span>.{' '}
          </p>
          {/*//Notes: control for do-not-track feature */}
          <p className="my-8 text-[#EDCB76] font-bold">CONTROLS FOR DO-NOT-TRACK FEATURES</p>

          <p className="mb-8">
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
            feature or setting you can activate to signal your privacy preference not to have data about your online
            browsing activities monitored and collected. At this stage no uniform technology standard for recognizing
            and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals
            or any other mechanism that automatically communicates your choice not to be tracked online. If a standard
            for online tracking is adopted that we must follow in the future, we will inform you about that practice in
            a revised version of this privacy notice.
          </p>

          {/*//Notes: do california residents have specific privacy rights */}
          <p className="my-8 text-[#EDCB76] font-bold">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>

          <p className="mb-8">
            In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your
            personal information.
          </p>

          <p className="mb-8">
            California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
            California residents to request and obtain from us, once a year and free of charge, information about
            categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
            the names and addresses of all third parties with which we shared personal information in the immediately
            preceding calendar year. If you are a California resident and would like to make such a request, please
            submit your request in writing to us using the contact information provided below.
          </p>

          <p className="mb-8">
            If you are under 18 years of age, reside in California, and have a registered account with Services, you
            have the right to request removal of unwanted data that you publicly post on the Services. To request
            removal of such data, please contact us using the contact information provided below and include the email
            address associated with your account and a statement that you reside in California. We will make sure the
            data is not publicly displayed on the Services, but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g., backups, etc.).
          </p>

          {/*//Notes: ccpa privacy notice */}
          <p className="my-8 text-[#EDCB76] font-bold">CCPA Privacy Notice</p>

          <p className="mb-8">The California Code of Regulations defines a "resident" as:</p>

          <ol className="list-decimal ml-8">
            <li>
              <p className="mb-8">
                every individual who is in the State of California for other than a temporary or transitory purpose and
              </p>
            </li>
            <li>
              <p className="mb-8">
                every individual who is domiciled in the State of California who is outside the State of California for
                a temporary or transitory purpose
              </p>
            </li>
          </ol>

          <p className="mb-8">All other individuals are defined as "non-residents."</p>

          <p className="mb-8">
            If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding
            your personal information.
          </p>

          <p className="mb-8 font-semibold">What categories of personal information do we collect?</p>
          <p className="mb-8">
            {' '}
            We have collected the following categories of personal information in the past twelve (12) months:
          </p>

          {/*//Notes: policy table */}
          <table className="w-full table-fixed">
            <thead>
              <tr className=" text-white uppercase text-sm leading-normal">
                <th className="w-1/3 py-6 px-6 text-left border border-white">Category</th>
                <th className="w-1/3 py-6 px-6 text-left border border-white">Category</th>
                <th className="w-1/3 py-6 px-6 text-left border border-white">Collected</th>
              </tr>
            </thead>
            <tbody className="text-white text-sm font-light">
              <tr className="border border-white ">
                <td className="w-1/3 py-6 px-6 text-left  border border-white">A. Identifiers</td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
                  personal identifier, online identifier, Internet Protocol address, email address, and account name
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">YES</td>
              </tr>
              <tr className="border border-white ">
                <td className="w-1/3 py-6 px-6 text-left border border-white">
                  B. Personal information categories listed in the California Customer Records statute
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Name, contact information, education, employment, employment history, and financial information
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">YES</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">
                  C. Protected classification characteristics under California or federal law
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">Gender and date of birth</td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">No</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">D. Commercial information</td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Transaction information, purchase history, financial details, and payment information
                </td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">YES</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">E. Biometric information</td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">Fingerprints and voiceprints</td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">
                  F. Internet or other similar network activity
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Browsing history, search history, online behavior, interest data, and interactions with our and other
                  websites, applications, systems, and advertisements
                </td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">G. Geolocation data</td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">Device location</td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">
                  H. Audio, electronic, visual, thermal, olfactory, or similar information
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  mages and audio, video or call recordings created in connection with our business activities
                </td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">
                  I. Professional or employment-related information
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Business contact details in order to provide you our Services at a business level or job title, work
                  history, and professional qualifications if you apply for a job with us
                </td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">J. Education Information</td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Student records and directory information
                </td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
              <tr className="border border-white">
                <td className="w-1/3 py-6 px-6 text-left border border-white">
                  K. Inferences drawn from other persona l information
                </td>
                <td className="w-1/3 py-6 px-6 text-left  border border-white">
                  Inferences drawn from any of the collected personal information listed above to create a profile or
                  summary about, for example, an individualâ€™s preferences and characteristics
                </td>
                <td className="w-1/3 py-6 px-6 text-left border border-white">NO</td>
              </tr>
            </tbody>
          </table>

          {/*//Notes: we may also connect other info */}
          <p className="mt-8 font-semibold">
            {' '}
            We may also collect other personal information outside of these categories through instances where you
            interact with us in person, online, or by phone or mail in the context of:
          </p>

          <ul className="list-disc ml-8">
            <li className="mt-4">
              <p>Receiving help through our customer support channels;</p>
            </li>
            <li className="mt-4">
              <p>Participation in customer surveys or contests; and</p>
            </li>
            <li className="mt-4">
              <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>
            </li>
          </ul>

          {/*//Notes: how do we use and share your personal info */}
          <p className="mt-8 font-semibold">How do we use and share your personal information?</p>

          <p className="mt-8">
            {' '}
            More information about our data collection and sharing practices can be found in this privacy notice. You
            may contact us by email at Privacy@competecae.com, or by referring to the contact details at the bottom of
            this document.
          </p>

          <p>
            {' '}
            f you are using an authorized agent to exercise your right to opt out we may deny a request if the
            authorized agent does not submit proof that they have been validly authorized to act on your behalf.
          </p>

          <p className="mt-8 font-semibold">Will your information be shared with anyone else?</p>

          <p className="mt-8">
            {' '}
            We may disclose your personal information with our service providers pursuant to a written contract between
            us and each service provider. Each service provider is a for-profit entity that processes the information on
            our behalf.
          </p>
          <p className="mt-4">
            {' '}
            We may use your personal information for our own business purposes, such as for undertaking internal
            research for technological development and demonstration. This is not considered to be "selling" of your
            personal information.
          </p>
          <p className="mt-4">
            {' '}
            Compete AE Inc. has not disclosed or sold any personal information to third parties for a business or
            commercial purpose in the preceding twelve (12) months. Compete AE Inc. will not sell personal information
            in the future belonging to website visitors, users, and other consumers.
          </p>

          {/*//Notes: your rights with respect to your data */}
          <p className="mt-8 font-semibold">Your rights with respect to your personal data</p>

          <p className="mt-8">Right to request deletion of the data â€” Request to delete</p>
          <p className="mt-4">
            You can ask for the deletion of your personal information. If you ask us to delete your personal
            information, we will respect your request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her
            right to free speech, our compliance requirements resulting from a legal obligation, or any processing that
            may be required to protect against illegal activities.
          </p>

          <p className="font-semibold mt-8">Right to be informed â€” Request to know</p>
          <p className="mt-8 font-semibold">Depending on the circumstances, you have a right to know:</p>

          <ul className="list-disc ml-8">
            <li className="mt-4">
              <p>whether we collect and use your personal information;</p>
            </li>
            <li className="mt-4">
              <p>the categories of personal information that we collect;</p>
            </li>
            <li className="mt-4">
              <p>the purposes for which the collected personal information is used;</p>
            </li>
            <li className="mt-4">
              <p>whether we sell your personal information to third parties;</p>
            </li>
            <li className="mt-4">
              <p>the categories of personal information that we sold or disclosed for a business purpose;</p>
            </li>
            <li className="mt-4">
              <p>
                the categories of third parties to whom the personal information was sold or disclosed for a business
                purpose; and
              </p>
            </li>
            <li className="mt-4">
              <p>the business or commercial purpose for collecting or selling personal information.</p>
            </li>
          </ul>

          <p className="mt-8">
            In accordance with applicable law, we are not obligated to provide or delete consumer information that is
            de-identified in response to a consumer request or to re-identify individual data to verify a consumer
            request.
          </p>
          <p className="mt-4">Right to Non-Discrimination for the Exercise of a Consumerâ€™s Privacy Rights</p>
          <p className="mt-4 mb-8">We will not discriminate against you if you exercise your privacy rights</p>

          {/*//Notes: verification process */}
          <p className="my-8 font-semibold"> Verification process</p>
          <p className="">
            {' '}
            Upon receiving your request, we will need to verify your identity to determine you are the same person about
            whom we have the information in our system. These verification efforts require us to ask you to provide
            information so that we can match it with information you have previously provided us. For instance,
            depending on the type of request you submit, we may ask you to provide certain information so that we can
            match the information you provide with the information we already have on file, or we may contact you
            through a communication method (e.g., phone or email) that you have previously provided to us. We may also
            use other verification methods as the circumstances dictate.
          </p>
          <p className="mt-8">
            We will only use personal information provided in your request to verify your identity or authority to make
            the request. To the extent possible, we will avoid requesting additional information from you for the
            purposes of verification. However, if we cannot verify your identity from the information already maintained
            by us, we may request that you provide additional information for the purposes of verifying your identity
            and for security or fraud-prevention purposes. We will delete such additionally provided information as soon
            as we finish verifying you.
          </p>

          {/*//Notes: others privacy rights */}
          <p className="mt-8 font-semibold"> Others privacy rights</p>

          <ul className="list-disc ml-8">
            <li className="mt-4">
              <p>You may object to the processing of your personal information.</p>
            </li>
            <li className="mt-4">
              <p>
                You may request correction of your personal data if it is incorrect or no longer relevant, or ask to
                restrict the processing of the information.
              </p>
            </li>
            <li className="mt-4">
              <p>
                You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a
                request from an authorized agent that does not submit proof that they have been validly authorized to
                act on your behalf in accordance with the CCPA.
              </p>
            </li>
            <li className="mt-4">
              <p>
                You may request to opt out from future selling of your personal information to third parties. Upon
                receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later
                than fifteen (15) days from the date of the request submission.
              </p>
            </li>
          </ul>
          <p className="mt-8">
            To exercise these rights, you can contact us by email at{' '}
            <span className="text-[#EDCB76]">Privacy@competecae.com</span>, or by referring to the contact details at
            the bottom of this document. If you have a complaint about how we handle your data, we would like to hear
            from you.
          </p>

          {/*//Notes: DO we make updates to this notice */}
          <p className="mt-8 text-[#EDCB76]">DO WE MAKE UPDATES TO THIS NOTICE?</p>
          <p className="mt-8">
            {' '}
            In Short:Yes, we will update this notice as necessary to stay compliant with relevant laws.
          </p>

          <p className="mt-8">
            We may update this privacy notice from time to time. The updated version will be indicated by an updated
            "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
            changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or
            by directly sending you a notification. We encourage you to review this privacy notice frequently to be
            informed of how we are protecting your information.
          </p>

          <p className="mt-8 text-[#EDCB76]"> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
          <p className="my-8 font-semibold">
            If you have questions or comments about this notice, you may email us at Privacy@competecae.com or by post
            to:
          </p>

          <p className="leading-loose">
            Compete AE Inc <br /> 293 N Brodway <br /> Yonker NY 10701 <br /> United states
          </p>

          <p className="my-8 text-[#EDCB76] "> HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? </p>

          <p className="my-8">
            You have the right to request access to the personal information we collect from you, change that
            information, or delete it. To request to review, update, or delete your personal information, please submit
            a request form by{' '}
            <span className="underline text-[#EDCB76]">
              <a href="https://app.termly.io/notify/415f241e-5358-4d19-8de3-9b36c3d568fe">clicking here</a>
            </span>
            .
          </p>
        </div>

        {/*//Notes: footer */}
        {/* <FooterSection /> */}
      </div>
    </div>
  );
}

export default Policy;
