import React from "react"
import {useEffect} from "react"
import {useState} from "react"
import {toast} from "react-toastify"
import {useAddToTeamQuery} from "../../../Store/api/ApiSlice"

const ConfirmationTable = ({title, data, eventId, refetch}) => {
  console.log("🚀 ~ ConfirmationTable ~ data:", data)
  const [participantId, setParticipantId] = useState("")
  const {
    isSuccess: AddTeamSuccess,
    data: ss,
    error,
    isError,
  } = useAddToTeamQuery(
    {
      participantId: participantId,
      eventId: eventId,
    },
    {
      skip: !participantId,
    }
  )
  const addToTeamHandeler = (id) => {
    setParticipantId(id)
    refetch()
    console.log("teamjas", isError, error)
    if (error?.data) {
      alert(error?.data?.message)
    }
  }
  useEffect(() => {
    // console.log("teamjas", isError, error)
    if (error) {
      alert(error?.data?.message)
    }
  }, [error])
  // console.log("🚀 ~ ConfirmationTable ~ data:sdjkfhk", error)
  return (
    <div class="flex flex-col items-start justify-center">
      <h3 className="fw-700 fs-20 textPrimary mb-0  mobile_size mt-4">{title}</h3>
      <div class="p-6 overflow-scroll px-0">
        <table class="w-full min-w-[300px] table-auto text-left">
          <thead>
            <tr>
              <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-3">
                <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                  Profile
                </p>
              </th>
              <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-3">
                <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                  Image
                </p>
              </th>
              {title.includes("Waiting") && (
                <th class="border-y border-blue-gray-100 bg-blue-gray-50/50 p-3">
                  <p class="block antialiased font-sans text-sm text-blue-gray-900 font-normal leading-none opacity-70">
                    Invite
                  </p>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.length &&
              data?.map((item, index) => (
                <tr>
                  <td class="p-3 border-b border-blue-gray-50">
                    <div class="flex items-center gap-3">
                      <img
                        src={item?.profileImage}
                        alt="Spotify"
                        class="inline-block relative object-center !rounded-full w-12 h-12 rounded-lg border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                      />
                    </div>
                  </td>
                  <td class="p-3 border-b border-blue-gray-50">
                    {/* <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal"> */}
                    <p class="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
                      {item?.userName}
                    </p>
                    {/* </p> */}
                  </td>
                  {title.includes("Waiting") && (
                    <td class="p-3 border-b border-blue-gray-50">
                      <div class="w-max">
                        <button
                          onClick={() => addToTeamHandeler(item?.participantId)}
                          className="p-2 font-bold text-[36px] w-8 h-8 flex items-center justify-center"
                        >
                          +
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ConfirmationTable
