import React from "react";

import logo from "../../assets/images/lgo.svg";
import AppStore from "../../assets/icons/appStore.svg";
import GooglePay from "../../assets/icons/googlePay.svg";
import { FaXTwitter } from "react-icons/fa6";
import { LuFacebook } from "react-icons/lu";
import { FaInstagram } from "react-icons/fa";
import { RiTiktokLine } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";

import "./style.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer pb-lg-0 pb-5 footContainer">
      <div className="container pb-lg-2 pb-5 ">
        <div className="row p-0 ">
          <div className="col-md-2 col-4 footerCol1">
            <h6 className="footTitle mb-1">About CompeteAE</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#">Press</a>
              </li>
              {/* <li>
                <a href="#">We are hiring!</a>
              </li>
              <li>
                <a href="#">Gift Cards</a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-2 col-4 footerCol2">
            <h6 className="footTitle mb-1">Support 24/7</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#">Contact Support</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-4 d-block footerCol2">
            <h6 className="footTitle mb-1">Legal</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">T&C</a>
              </li>
            </ul>
          </div>
          {/* <div
            className="col-md-3 d-lg-none d-block footerCol4"
            style={{ marginTop: "-40px" }}
          >
            <div className="footer-logo mb-1 d-flex justify-content-center align-items-center">
              <img src={logo} alt="CompeteAE Logo" className="mx-auto" />
            </div>
          </div> */}
          <div className="col-md-3 d-lg-block d-none justify-center footerCol3">
            <h6 className="footTitle text-center mb-3 d-lg-block">Follow us</h6>
            <ul className="list-unstyled justify-content-lg-center justify-content-center gap-1  mb-0 d-flex flex-wrap text-center mx-4">
              {/* <li>
                <a href="#">
                  <LuFacebook className="fs-2" />
                </a>
              </li> */}
              <li>
                <a href="#">
                  {/* X (Twitter)
                   */}
                  <FaXTwitter className="fs-2 ms-2" />
                </a>
              </li>
              <li>
              <a href="https://www.instagram.com/competeae_inc/">
                    <FaInstagram className="fs-2 ms-2" />
                  </a>
              </li>
              <li>
                <a href="#">
                  <RiTiktokLine className="fs-2 ms-2" />
                </a>
              </li>
              {/* <li>
                <a href="#">
                  <CiLinkedin className="fs-2 ms-2" />
                </a>
              </li> */}
              <li>
                  <a href="https://www.youtube.com/@CompeteAE" target="blank">
                    <CiYoutube className="fs-2 ms-2" />
                  </a>
                </li>
            </ul>
          </div>
          <div
            className="col-md-3 footerCol4 d-lg-block d-none  justify-center gap-3 align-items-center"
            // style={{ marginTop: "-40px" }}
          >
            {/* <div className="footer-logo mb-1 d-lg-flex d-none justify-content-center align-items-center">
              <img src={logo} alt="CompeteAE Logo" />
            </div> */}
            <p className="text-center footTitle fw-700">
              {/* Don't have the app yet? */}
              Download App 
            </p>
            {/* <p className="searchText">
              Search or filter to find nearby experiences and upcoming events
              catered to you.
            </p> */}
            <div className="app-links d-flex justify-content-center footPayment">
              <a
                href="https://apps.apple.com/us/app/competeae/id6444800738"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-auto"
              >
                <img src={AppStore} alt="App Store" className="mx-auto mt-1" />
              </a>
            </div>
          </div>
          <div className="row mx-auto justify-content-center">
            <div className="col-12 d-md-none text-center">
              {/* <h6 className="footTitle mb-3">Follow us</h6> */}
              <ul className="list-unstyled d-flex justify-content-between px-3 gap-2 mb-0">
                <li>
                  <a href="#">
                    <FaXTwitter className="fs-2 ms-2" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/competeae_inc/">
                    <FaInstagram className="fs-2 ms-2" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <RiTiktokLine className="fs-2 ms-2" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@CompeteAE" target="blank">
                    <CiYoutube className="fs-2 ms-2" />
                  </a>
                </li>
              </ul>
            </div>

            {/* <div className="col-6 d-md-none text-center">
              <p className="footTitle fw-700">Download App</p>
              <div className="d-flex justify-content-center">
                <a
                  href="https://apps.apple.com/us/app/competeae/id6444800738"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-auto"
                >
                  <img src={AppStore} alt="App Store" className="mt-1" />
                </a>
              </div>
            </div> */}
          
          </div>
        </div>
      </div>
      <div className="footer-logo  pb-2 d-lg-flex d-none justify-content-center align-items-center">
        <img src={logo} alt="CompeteAE Logo" />
      </div>
      {/* <p className="mb-0 text-start px-3">&copy;2024 CompeteAE</p> */}
      {/* <div className="footer-bottom text-center pb-lg-0 pb-5 mb-2">
        <div className="d-flex justify-content-between px-20 py-2 copyRightSec">
          <p className="mb-0">
            Terms of use | Cookies Policy | Do Not Sell My
            Personal Information | Cookies Management
          </p>
          <p className="mb-0">&copy;2024 CompeteAE</p>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
