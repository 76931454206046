import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Container } from "react-bootstrap";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import EventCards from "./EventCards";
import "./eventCarasoul.css";
import "./upcomingEventSearch.css";

const EventCreationCard = ({ data, formattedStartDate }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col aling_cards ">
      {data?.map((eventData, index) => (
        <div key={index} className="text-white d-flex flex-col cursor-pointer">
          <p className="text-start text-20 eventTitle font-semibold">
            {eventData?.category?.name}
          </p>
          <div className="similarExpTopSecEvent mt-lg-4 mt-0 mb-3">
            <Container className="container-12 relative">
              <Swiper
                className="centered-swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={6}
                slidesPerView={4}
                breakpoints={{
                  0: { slidesPerView: 1.2 },
                  576: { slidesPerView: 2 },
                  992: { slidesPerView: 3 },
                  1200: { slidesPerView: 3 },
                }}
                navigation={{
                  nextEl: `.swiper-button-next-custom-${index}`,
                  prevEl: `.swiper-button-prev-custom-${index}`,
                }}
                pagination={{ clickable: true }}
                loop
              >
                {eventData?.events?.map((event, categoryIndex) => (
                  <SwiperSlide key={categoryIndex}>
                    <EventCards
                      categoryName={eventData?.category?.name}
                      event={event}
                      categoryIndex={categoryIndex}
                      index={categoryIndex}
                      navigate={navigate}
                      formattedStartDate={formattedStartDate}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className={`swiper-button-next swiper-button-next-custom swiper-button-next-custom-${index}`}
              ></div>
              <div
                className={`swiper-button-prev swiper-button-prev-custom swiper-button-prev-custom-${index}`}
              ></div>
            </Container>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventCreationCard;