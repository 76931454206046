import React, {useEffect, useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import OTPInput from "react-otp-input"
import {useNavigate} from "react-router-dom"

import {IoMdClose} from "react-icons/io"
import {IoArrowBackOutline} from "react-icons/io5"
import {FaEye} from "react-icons/fa"
import {AiFillAndroid} from "react-icons/ai"
import {toast} from "react-toastify"
import {
  useResendOtpMutation,
  useVerifyOTPMutation,
} from "../../../../Store/api/ApiSlice"

const VerifyOtp = ({
  setView,
  imageUrl,
  usrname,
  password,
  email,
  phoneNumber,
  uniqueCode,
  fullName,
  referrelCode,
  refetch,
}) => {
  // console.log("🚀 ~ fullName:", fullName)
  const navigate = useNavigate()
  const [OTPVerification, {isSuccess}] = useVerifyOTPMutation()
  const [ResendOtpFunc, {isSuccess: resendOtpSuccess}] = useResendOtpMutation()
  const [otp, setOtp] = useState("")
  const [timeLeft, setTimeLeft] = useState(60)
  const [isResendDisabled, setIsResendDisabled] = useState(true)

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
      return () => clearTimeout(timer)
    } else {
      setIsResendDisabled(false)
    }
  }, [timeLeft])

  const handleResendOtp = () => {
    const body = {
      uniqueCode: uniqueCode,
      phone: phoneNumber,
    }
    ResendOtpFunc(body)
      .then((res) => {
        console.log("🚀 ~ ResendOtpFunc ~ res:", res)
        setTimeLeft(60)
        setIsResendDisabled(true)
      })
      .catch((err) => {
        console.log("🚀 ~ ResendOtpFunc ~ err:", err)
      })
  }

  const handleChange = (value) => {
    setOtp(value)
  }

  const verifyyOtpFunc = (e) => {
    e.preventDefault()
    const body = {
      uniqueCode: uniqueCode,
      phone: phoneNumber,
      code: otp,
      user_name: usrname,
      email: email,
      password: password,
      fullName: "fullName f",
      image: imageUrl,
      referralCode: referrelCode,
      fcmToken: "fcmToken",
    }
    // console.log("🚀 ~ verifyyOtpFunc ~ body:", body)
    OTPVerification(body)
      .then((res) => {
        console.log("🚀 ~ OTPVerification ~ res:", res)
        if (res?.data?.accessToken) {
          localStorage.setItem("loginData", JSON.stringify(res?.data))
          toast.success("User created succesfully")
          navigate("/eventPage")
          window.location.reload()
          setView(0)
          refetch()
        } else {
          toast.error("Error in verifying otp" + res?.data?.message)
          alert("Error in verifying otp" + res?.data?.message)
        }
      })
      .catch((err) => {
        console.log("🚀 ~ OTPVerification ~ err:", err)
      })
  }
  return (
    <>
      <Modal.Header className="border-none d-flex justify-center">
        <div className="flex gap-2 items-center">
          <IoArrowBackOutline
            color="#ffffff"
            fontSize={30}
            onClick={() => setView(3)}
            className="cursor-pointer"
          />
          <h3 className="fs-4 text-white fw-600">Verify OTP</h3>
        </div>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setView(0)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={verifyyOtpFunc}>
          <div className="d-flex justify-center items-center pt-2">
            <AiFillAndroid color="#fad550" size={70} />
          </div>
          <div className="d-flex justify-center items-center py-3">
            <OTPInput
              inputStyle={{
                width: "4vw",
                maxWidth: "48px",
                aspectRatio: "1/1",
                fontSize: "18px",
                borderRadius: "5px",
                border: "1px solid #CCCFD3",
                outline: "none",
                backgroundColor: "white",
                textAlign: "center",
                justifyContent: "center",
                transition: "background-color 0.3s",
                color: "black",
                marginLeft: "10px",
              }}
              numInputs={4}
              value={otp}
              onChange={handleChange}
              renderInput={(props) => (
                <input
                  {...props}
                  type="text"
                  inputMode="numeric"
                  maxLength={1}
                  pattern="\d*"
                />
              )}
            />
          </div>
          <div>
            <p className=" text-white fs-6 fw-600 text-center">
              Resend Code :{" "}
              <span
                className="textSecondary fs-6 fw-600 cursor-pointer"
                onClick={handleResendOtp}
              >
                {timeLeft > 0
                  ? `00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`
                  : "Resend now"}
              </span>
            </p>
          </div>
          <div className=" w-100 pt-3 d-flex flex-col items-center">
            <Button
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
            >
              submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  )
}

export default VerifyOtp
