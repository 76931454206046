import {useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import {FaEye, FaEyeSlash} from "react-icons/fa"
import {IoMdClose} from "react-icons/io"
import {
  useSignInMutation,
  useSignupMutation,
} from "../../../../Store/api/ApiSlice"
import logo from "../../../../assets/images/lgo.svg"
import "./style.css"
import {useAuth} from "../../../../Contex/AuthContext"
import {toast} from "react-toastify"

const Login = ({setAuthorisedUser}) => {
  const {setView} = useAuth()
  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false)
  const [SignIn, {isSuccess}] = useSignInMutation()
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const handleUsernameChange = (e) => setUserName(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const loginFunc = (e) => {
    e.preventDefault()
    SignIn({username: userName, password: password})
      .unwrap()
      .then((res) => {
        // console.log("🚀 ~ .then ~ res:", res)
        if (res?.accessToken) {
          localStorage.setItem("loginData", JSON.stringify(res))
          window.location.reload()
          setAuthorisedUser(true)
          toast.success("LoggedIn successfully!")
          setView(0)
        } else {
          // alert(res?.data?.error)
          // toast.error(res?.data?.error)
        }
      })
      .catch((e) => {
        console.log("error", e)
        alert(e?.data?.error)
        toast.error(e?.data?.error)
      })
  }

  return (
    <>
      <Modal.Header>
        <h3 className="fs-4 text-white fw-600">Login</h3>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setView(0)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-1 d-flex justify-center">
          <img
            src={logo}
            alt="Compete AE"
            className=""
            style={{width: "150px"}}
          />
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="formUsername">
            <p className="popupLabels pb-1 fw-400 text-white">Username</p>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              onChange={handleUsernameChange}
            />
            <div className="text-muted">
              <p className="popupLabels pt-1 fw-400 text-white float-end">
                Forgot Username?
              </p>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formPassword">
            <p className="popupLabels pb-1 fw-400 text-white">Password</p>
            <div className="position-relative">
              <Form.Control
                type={passwordShown ? "text" : "password"}
                placeholder="Enter Password"
                onChange={handlePasswordChange}
              />
              <div
                className="position-absolute right-2 top-2"
                onClick={togglePasswordVisibility}
              >
                {passwordShown ? (
                  <FaEyeSlash color="white" size={20} />
                ) : (
                  <FaEye color="white" size={20} />
                )}
              </div>
            </div>
            <div className="text-muted">
              <p className="popupLabels pt-1 fw-400 text-white float-end">
                Forgot Password?
              </p>
            </div>
          </Form.Group>
          <div className=" w-100 pt-3 d-flex flex-col items-center">
            <Button
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
              onClick={loginFunc}
            >
              Login
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <p className="text-white h6 fw-500">
          Don't have an account?{" "}
          <span
            className="fs-6 pl-1 text-white fw--700 no-underline cursor-pointer"
            onClick={() => setView(2)}
          >
            Sign Up
          </span>
        </p>
      </Modal.Footer>
    </>
  )
}

export default Login
