import React from "react";
import competeae from "../../assets/competeae.mp4";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import "./cator.css";
const Cator = () => {
  return (
    <div>
      <div className="container mt-lg-5 mt-0 pt-lg-5 pt-0">
        <div className="row ">
          <div className="col-lg-3"></div>
          <div className="col-lg-7 bg_video p-lg-0 p-3">
          <div className=" border-video  rounded_vedio p-0">
            {/* <video width="550" height="300" controls className="mob_video bor_video">
      <source src={competeae} type="video/mp4"/>
     </video> */}
            <Player
              playsInline
              poster="/assets/poster.png"
              src={competeae}
              className="rounded_video"
              autoPlay={true}
              muted
            />
          </div>

          </div>
          <div className="col-lg-2"></div>

          <div className="col-lg-12">
            <div className="work-title"></div>
            <h2 className="mrgin container-8-2  text-center mob_fon2t">
              CompeteAE Much More than <br className="d-lg-block d-none" /> a
              Goals App
            </h2>
            <div className="row  mt-4 p-4 justify-content-between">
              <div className="col-lg-3 col-6 px-2">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center">Voting</h4>
                  <p className="work-title text-center">
                    Vote and predict the outcome of a competition.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6 px-2 mt-lg-0">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center">Feed</h4>
                  <p className="work-title text-center">
                    Competitors thoughts and Media from their competitions.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-6 px-2 mt-lg-0 mt-3">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center">Chats</h4>
                  <p className="work-title text-center">
                    Strategize with your team on how to beat the other team.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6 px-2 mt-lg-0 mt-3">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center"> Leaderboard</h4>
                  <p className="work-title text-center">
                    See where you stack against the rest of the CAE community.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-lg-3 p-4 pt-0">
              <div className="col-lg-3 col-6 px-2">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center"> Journals</h4>
                  <p className="work-title text-center">
                    Journal your thoughts and activity for each Competition.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6  mt-lg-0 px-2">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center">Device connections</h4>
                  <p className="work-title text-center">
                    Connect to any of our 5+ Devices, leaving you to only worry
                    about the competition.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-6 mt-lg-0 px-2 mt-3">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center"> Profit Sharing</h4>
                  <p className="work-title text-center">
                    Both CompeteAE and Competitors agree to share profits with
                    Voters.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-6 mt-lg-0 mt-3 px-2">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center">Whistle Blower</h4>
                  <p className="work-title text-center">
                    Anyone can Report a Cheater and earn a Reward.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-lg-flex p-3 d-block justify-content-center gap-2 mt-lg-3 pt-0">
              <div className="col-lg-3 col-6">
                <div className="card_cator ring-1 ring-[#AF8BFF]" data-aos="fade-up">
                  <h4 className="clr text-center"> Coach CAE</h4>
                  <p className="work-title text-center">
                    Your personal AiCoach for Finance, Fitness, Learning, Habits
                    and Sports
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            {/* <div className="work-title"></div>
            <h2 className="mt-3 expand-head">CompeteAE Much More than a Goals App</h2>
            <div className="d-flex flex-wrap gap-3">
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4>Voting</h4>
                  <p className="work-title">
                  Vote and predict the outcome of a competition.  
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4>Feed</h4>
                  <p className="work-title">
                  Competitors thoughts and Media from their competitions.  
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3 mt-3">
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4>Chats</h4>
                  <p className="work-title">
                  Strategize with your team on how to beat the other team.    
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4> Leaderboard</h4>
                  <p className="work-title">
                  See where you stack against the rest of the CAE community.
                  </p>
                </div>
              </div>


            
            </div>
            <div className="d-flex flex-wrap gap-3 mt-3">
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4> Journals</h4>
                  <p className="work-title">
                  Journal your thoughts and activity for each Competition.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4>Device connections</h4>
                  <p className="work-title">
                  Connect to any of our 5+ Devices, leaving you to only worry about the competition.   
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-3 mt-3">
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4> Profit Sharing</h4>
                  <p className="work-title">
                  Both CompeteAE and Competitors agree to share profits with Voters.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="card_cator" data-aos="fade-up">
                  <h4>Whistle Blower</h4>
                  <p className="work-title">
                  Anyone can Report a Cheater and earn a Reward. 
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cator;
