import React from "react";
import { Button } from "react-bootstrap";
import Planet from "../../assets/Planet.webp";
import compete from "../../assets/compete.jpeg";
import testi from "../../assets/testi.webp";
import "./Take.css";

import glows from "../../assets/backgrounds/Group6.png";
import background from "../../assets/backgrounds/Rectangle46.png";

const TakeControl = () => {
  return (
    <div className="position-relative mt-2">
      {/* <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="work-title">TAKE CONTROL OF</div>
            <h2 className="mt-3 expand-head">
              Your time, your rate, & your terms
            </h2>
            <p className="work-title">
              Take pride in your work and the freedom to do it your way. Whether
              that means 1 hour a week or 40, Pangea’s flexible contracts let
              you set the pace. And if you find the right fit, you can join a
              company full-time without any fees. Just another way we are
              breaking the mold.
            </p>
            <Button variant="dark" className="cta-btn">
              Create Your Profile
            </Button>
          </div>
          <div className="col-lg-6 col-12 pos">
            <img
              src={Planet}
              alt=""
              className="planet_img"
              data-aos="fade-up"
              data-aos-duration="3000"
            />
            <img
              src={compete}
              alt=""
              className="compete_img"
              data-aos="fade-up"
              data-aos-duration="3000"
            />
          </div>
        </div>
      </div> */}
      <img className="glows-background" src={glows} alt="glows" />
      <img className="glows-background-left" src={background} alt="glows" />
      <div className="container">
        <div className="row ">
          <h1 className="container-8-2 text-center mb-4">How It Works</h1>

          <div className="col-lg-3 mb-3">
            <div
              className="card_trust"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <h3>Create A Competition</h3>
              <p className="work-title">
                {" "}
                Choose in what you want to compete and against who you want to
                compete.
              </p>
            </div>
          </div>
          <div className="col-lg-3 mb-3">
            <div
              className="card_trust"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <h3>Share Competition</h3>
              <p className="work-title">
                {" "}
                Share Your Competition with friends or foes and have them Vote
                on the Outcome.
              </p>
            </div>
          </div>
          <div className="col-lg-3 mb-3">
            <div
              className="card_trust"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <h3>Compete and Update</h3>
              <p className="work-title">
                {" "}
                Update your progress with Videos, Images, Messages and upload
                your current status.
              </p>
            </div>
          </div>
          <div className="col-lg-3 mb-3">
            <div
              className="card_trust"
              data-aos="zoom-in"
              data-aos-duration="5000"
            >
              <h3>Earn</h3>
              <p className="work-title">
                {" "}
                Winner of the Competition and Voters Earns Real Money, Everyone
                Learns and Improves
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakeControl;
